import { Fragment, useEffect, useMemo, useState } from 'react';
import { ArchiveBoxIcon, CogIcon, EllipsisVerticalIcon, RectangleStackIcon } from '@heroicons/react/24/solid';
import FlyoutMini from 'components/FlyoutMini/FlyoutMini';
import { Flyout, useFlyout } from 'components/Flyout/Flyout';
import { useQuery } from '@apollo/client';
import { GetMroPartDocument } from 'graphql/generated';
import { Link, useParams } from 'react-router-dom';
import Inventory from './Inventory';
import { Menu, Transition } from '@headlessui/react';
import Button from 'components/Button/Button';
import NewInventoryFlyout from './Inventory/New';
import Requests from '../Requests';
import TabCards from 'components/TabCards/TabCards';
import SerializedInventory from './SerializedInventory';
import PartInfo from './Partials/PartInfo';
import NewSerializedInventoryFlyout from './SerializedInventory/New';
import Orders from '../Orders';
import { PartsContext } from '..';

const Part = function () {
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { flyoutProps: flyoutMiniProps } = useFlyout();
  const { partId } = useParams();
  const { data: { mroPart } = {} } = useQuery(GetMroPartDocument, {
    variables: { partId },
  });
  const tabs = useMemo(
    () => [
      {
        title: 'Overview',
        content: (
          <div className="p-6">
            <PartInfo mroPart={mroPart} />
            <div className="flex flex-col w-full">
              <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
                <ArchiveBoxIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
                <h1 className="text-brand-dark font-bold">{mroPart?.isSerialized ? 'Serialized Parts' : 'Inventory'}</h1>
              </div>
              {mroPart?.isSerialized ? (
                <SerializedInventory updateFlyout={updateFlyout} closeFlyout={closeFlyout} partId={partId} />
              ) : (
                <Inventory updateFlyout={updateFlyout} closeFlyout={closeFlyout} partId={partId} />
              )}
              <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
            </div>
            <div className="flex justify-end mt-2">
              {mroPart?.isSerialized ? (
                <Button
                  text="Add Serialized Part"
                  onClick={() =>
                    updateFlyout({
                      title: 'New Serialized Part',
                      content: <NewSerializedInventoryFlyout partId={partId} closeFlyout={closeFlyout} />,
                    })
                  }
                  color="navy"
                  size="xs"
                />
              ) : (
                <Button
                  text="Add Inventory"
                  onClick={() =>
                    updateFlyout({ title: 'New Inventory', content: <NewInventoryFlyout partId={partId} closeFlyout={closeFlyout} /> })
                  }
                  color="navy"
                  size="xs"
                />
              )}
            </div>
          </div>
        ),
      },
      {
        title: 'Requests',
        content: (
          <div className="flex flex-col w-full p-6">
            <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
              <RectangleStackIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
              <h1 className="text-brand-dark font-bold">Requests</h1>
            </div>
            <Requests updateFlyout={updateFlyout} partId={partId} />
            <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
          </div>
        ),
      },
      {
        title: 'Orders',
        content: (
          <div className="flex flex-col w-full p-6">
            <div className="flex items-center gap-2 p-4 bg-white border border-b-0 border-slate-200 rounded-t">
              <RectangleStackIcon className="h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm" />
              <h1 className="text-brand-dark font-bold">Orders</h1>
            </div>
            <Orders closeFlyout={closeFlyout} updateFlyout={updateFlyout} partId={partId} />
            <div className="bg-white border-t-0 border border-slate-200 rounded-b p-3 w-full"></div>
          </div>
        ),
      },
    ],
    [mroPart]
  );
  const [currentTab, setCurrentTab] = useState(tabs[0].title);
  useEffect(() => {
    setCurrentTab(tabs[0].title); // reset tab to first tab when mroPart changes
  }, [mroPart, tabs]);
  return (
    <>
      <FlyoutMini {...flyoutMiniProps} />
      <Flyout {...flyoutProps} />
      <div className="flex flex-col gap-2">
        <div className="bg-white border rounded border-slate-300 shadow-blue">
          {/* HEADER SECTION */}
          <div className="flex flex-col">
            <div className="flex rounded-t items-center justify-between p-6 wrap">
              <div className="flex items-center">
                <CogIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
                <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">{mroPart?.partNumber}</h1>
              </div>
              <Menu
                as="div"
                className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                <Menu.Button className="">
                  <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="edit">
                          <button
                            className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                            onClick={() => {}}>
                            Edit
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="flex justify-between items-end p-4 px-6 bg-slate-50 border-slate-300 border-t rounded-b w-full overflow-hidden">
              <ul className="flex">
                <li className="flex-col mr-4">
                  <h3 className="font-bold text-brand-dark -mb-1">{mroPart?.manufacturer?.name !== '' ? mroPart?.manufacturer?.name : 'N/A'}</h3>
                  <span className="text-xs text-slate-500">MANUFACTURER</span>
                </li>
                <li className="flex-col mr-4">
                  <h3 className="font-bold text-brand-dark -mb-1">{mroPart?.isSerialized ? 'Yes' : 'No'}</h3>
                  <span className="text-xs text-slate-500">SERIALIZED</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <PartsContext.Provider value={{ filter: '', setFilter: () => {}, partsTab: '' }}>
          <TabCards items={tabs} setActiveTitle={setCurrentTab} activeTitle={currentTab} />
        </PartsContext.Provider>
      </div>
    </>
  );
};

export default Part;
