import { useEffect, useState } from 'react';
import { useSession } from 'contexts/SessionContext';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { GetLogbookDocument, GetLogEntryDocument, UpdateLogEntryDocument } from 'graphql/generated';
import DatePicker from 'components/DatePicker/DatePicker';
import Card from 'components/Card/Card';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import FilePicker from 'components/FilePicker/FilePicker';
import JascSelect from 'components/JascCategories/JascSelect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
type EntryFiles = File | { signedUrl?: string; name?:string; id?: string };

const Edit = function ({ id, closeFlyout } : { id: string, closeFlyout: (didDelete?:boolean) => void}) {  
  const { data : { logEntry } = { } } = useQuery(GetLogEntryDocument, { variables: { logEntryId: id } });
  const [updateLogEntry] = useMutation(UpdateLogEntryDocument, {
    refetchQueries: [{ query: GetLogEntryDocument, variables: { logEntryId:id } }, { query: GetLogbookDocument, variables: { logBookId:logEntry?.logBook?.id } }],
    onCompleted: () => closeFlyout(),
  });
  const [files, setFiles] = useState<EntryFiles[]>([]);
  const [prefill, setPrefill] = useState({
    logDate: new Date().setHours(0, 0, 0, 0),
    title: '',
    description: '',
    intervalValues: [],
    systemsAffected: [],
  });
  useEffect(() => {
    if(logEntry){
        setFiles( logEntry?.attachments?.map((attach) => ({  id: attach.id, signedUrl: attach.signedUrl, name: attach.name })) );
        setPrefill({
            title: logEntry?.title ?? '',
            description: logEntry?.description ?? '',
            logDate: logEntry?.logDate ?? '',
            intervalValues: logEntry?.intervalValues,
            systemsAffected: logEntry.systemsAffected?.map((system) => system.id+'/'+system.name+'/'+system.code ),
        })
    }
  }, [logEntry]);

  const handleSubmit = async (values: typeof prefill, { setSubmitting }: FormikHelpers<typeof prefill>) => {
    const systemsAffected = values?.systemsAffected?.map((system) => { 
      return ({ id: system as string }) });

    delete values.systemsAffected;
    await updateLogEntry({ variables: { input: { id, ...values,
      systemsAffected: { 
        disconnect: logEntry?.systemsAffected.filter((system) => !systemsAffected?.find((sys) => sys?.id.split('/')[0] === system.id ) ).map((system) => ({ id: system.id })), 
        connect: systemsAffected?.map((system) => ({ id: system.id.split('/')[0] })) 
      },
      attachments: {
        ...( logEntry?.attachments.filter((attach) => attach.id ).length > 0 &&
            { disconnect: logEntry?.attachments.map((attach) => ({ id: attach?.id }) ) }
        ),
        ...( files.filter((attach) => (attach as any)?.id ).length > 0 &&
          { connect: files.map((attach) => ({ id: (attach as any)?.id }) ) }
        )
      },
    } } });
    if (files.filter((file) => (file as any)?.size)) {
      const formData = new FormData();
      files.filter((file) => (file as any)?.size).forEach((file) => {
        formData.append('files', file as any);
      });
      formData.append('logEntryId', id);
      const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
      console.log(res);
    }
    setSubmitting(false);
    closeFlyout();
  };
  return (
    <div className="px-4">
      <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="">
            <Card>
              <dl>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
                </div>
                <div className="flex justify-between items-center p-4">
                  <label htmlFor="logDate" className="w-1/3 text-slate-500">
                    Date
                  </label>
                  <Field as={DatePicker} name="logDate" id="logDate" className="w-2/3 rounded border px-2 border-slate-300 text-sm" />
                </div>
                <div className="p-4 flex items-center justify-center">
                  <div className='w-1/3 mb-[-15px]'>
                    <dt className="text-sm font-medium text-slate-500">Systems Affected</dt>
                    <a href='https://sdrs.faa.gov/documents/JASC_Code.pdf' target='_' className="text-xs font-medium text-slate-500 underline">(JASC Table And Definitions)</a>
                  </div>
                  <Field
                    as={JascSelect}
                    name="systemsAffected"
                    className="w-2/3"
                    onChange={(value) => {
                      setFieldValue('systemsAffected', value);
                    }}>
                    <option>1230 - Hydraulic Fuel servicing</option>
                  </Field>
                </div>
              </dl>
            </Card>
            {logEntry?.intervalValues && Object.keys(logEntry?.intervalValues)?.length > 0 && <Card title='Intervals'>
              <div className='w-full flex gap-3 justify-around'>
                {Object.keys(values.intervalValues).map( (key, index) => {
                    const value = values.intervalValues[key];
                    return (
                    <div className="flex flex-col gap-2" key={index}>
                        <h3 className="text-slate-500">{key}</h3>
                        <input value={value} onChange={ (e) => {
                            const tempIntervals = {...values.intervalValues};
                            tempIntervals[key] = e.target.value;
                            setFieldValue('intervals', tempIntervals);
                        }} 
                        className="max-w-sm rounded bg-slate-50 border-slate-200 border text-brand focus:outline-0 px-3 py-2 placeholder:text-slate-400 placeholder:text-sm" />               
                    </div>
                    )}
                )}
              </div>
            </Card> }
            <Card title='Description' >
              <div>
                <ReactQuill theme="snow" value={values.description} onChange={(value) => setFieldValue('description', value)} />
              </div>
            </Card>
            <Card title='Attachments' >
              <FilePicker files={(files as any)} setFiles={setFiles} />
            </Card>
            <div className="pt-3 pb-20">
              <div className="flex justify-end gap-4">
                <Button
                  text="Cancel"
                  color="pale"
                  size="xs"
                  onClick={() => closeFlyout()} />
                <Button
                  text="Update Entry"
                  type="submit"
                  color="navy"
                  size="xs" /> 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Edit;
