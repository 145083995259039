import { useQuery } from "@apollo/client";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/solid";
import { useSession } from "contexts";
import { GetOrganizationDocument } from "graphql/generated";
import { useIntercom } from "react-use-intercom";

export default function IntercomChat() {
    const { user, organizationType, organizationSubtype, organizationId } = useSession();
    const { update } = useIntercom();
    const { data : { organization } = {} } = useQuery(GetOrganizationDocument, { variables: { id: organizationId }, skip: !organizationId });
    if(user){
        update({
            userId: user?.legacyId ?? user?.id,
            email: user?.email,
            customLauncherSelector: '#intercom-launcher',    
            name: user?.firstName || user?.lastName ? (user?.firstName + ' ' + (user?.lastName ?? '')) : user?.email ?? 'New Unknown User',
            company: {
                companyId: organization?.legacyId ?? organizationId,
                name: organization?.name,
            },
            customAttributes: { verison: '2.0.0', organizationSubtype, organizationType }, 
            hideDefaultLauncher: true
        }); 
    }else{
        update({
            customLauncherSelector: '#intercom-launcher',    
            hideDefaultLauncher: true
        });
    }
   
    return (
        <ChatBubbleLeftIcon id={"intercom-launcher"} className="fixed md:bottom-4 bottom-[80px] right-4 h-14 w-14 text-white bg-brand-purple cursor-pointer z-50 rounded-[100px] p-2 scale-x-[-1]" />
    );
}