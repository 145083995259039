import { addMinutes, subMinutes } from 'date-fns';
export const addTime = (date: Date | string, minutes: number): Date => {
  const dateObj = new Date(date);
  return addMinutes(dateObj, 30);
};
export const subTime = (date: Date | string, minutes: number): Date => {
  const dateObj = new Date(date);
  return subMinutes(dateObj, 30);
};
export const toLocalISOTime = (date: Date | string): string => {
  const dateObj = new Date(date);
  var tzoffset = new Date().getTimezoneOffset() * 60000;
  return new Date(dateObj.getTime() - tzoffset).toISOString().slice(0, -1);
};
