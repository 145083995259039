import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { SignUpContext } from '..';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { GetCouponCodeDocument, GetCouponCodeQuery, NewUserSignupDocument, OnboardOrgDocument, Role, SignupForServiceDocument } from 'graphql/generated';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import * as Yup from 'yup';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useNavigate } from 'react-router-dom';

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters long'),
});



const CreateAccount = (props) => {
    const { setLogin, formStep, changeStep, coupon: couponCode } = useContext(SignUpContext);
    const [ searchCoupons ] = useLazyQuery( GetCouponCodeDocument );
    const [signup] = useMutation(NewUserSignupDocument);
    const navigate = useNavigate();
    const { toastProps, showToast } = useToast();
    const [ signupValues, setSignupValues ] = useState({ name: '', email: '', password: '', confirm: '', coupon: couponCode });
    const [ coupon, setCoupon ] = useState<GetCouponCodeQuery['getCouponCode']>( null );

    const searchCoupon = async (code) => { 
        const results = await searchCoupons({ variables:{ code: code.toUpperCase() } });
        if(results.data.getCouponCode) {
            if(results.data.getCouponCode.active){
                setCoupon(results.data.getCouponCode);
            }else{
                showToast({ title: 'Coupon is not active', type: ToastLength.Long });
            }
        }else{
            showToast({ title: 'Invalid Coupon Code', type: ToastLength.Long });
        }
    }

    useEffect( ()=>{
        if(couponCode){
            setSignupValues({ ...signupValues, coupon: couponCode });
            searchCoupon(couponCode);
        }
    }, [couponCode] );
    return (
        <div className='relative'>
            <Toast {...toastProps} />
            <Formik
                validationSchema={SignUpSchema}
                initialValues={signupValues}
                onSubmit={async (values) => {
                try {
                    const firstName = values.name.split(' ')[0];
                    const lastName = values.name.split(' ')[1];
                    const { data } = await signup({ variables: { input: { email: values.email, password: values.password, firstName, lastName, metadata:{ coupon: coupon?.id } } } });
                    if (data.signup.user) {
                        setLogin(data.signup);
                    }
                    changeStep(formStep, 'addTailNumber');
                } catch (error) {
                    const { message } = error as ApolloError;
                    showToast({ title:message, type: ToastLength.Long });
                
                }}}>
                {({ values, isSubmitting, errors }) => (
                <Form className="w-80 md:w-96">
                    <div className="flex flex-col mb-4">
                        <span className="text-3xl md:text-2xl font-bold text-brand mt-10">Welcome to Coflyt!</span>
                    </div>
                    <div className="flex flex-col mb-4">
                        <span className="font-semibold text-gray-400">Start flying with pilot peace of mind.</span>
                    </div>
                    <div className="flex flex-col">
                        <FullFormikInput name="name" label="Name" autoComplete="off" />
                    </div>
                    <div className="flex flex-col">
                        <FullFormikInput name="email" label="Email" type='email' />
                    </div>
                    <div className="flex flex-col">
                        <FullFormikInput name="password" type="password" label="Password" autoComplete="off" />
                    </div>
                    <div className="flex flex-col">
                        <FullFormikInput name="confirm" type="password" label="Confirm Password" validate={(value) => (value === values.password ? '' : "Passwords Don't Match")} />
                    </div>
                    <div className="flex flex-col pt-4 gap-2">
                        <span className='text-sm'>Promo Code</span>
                        {coupon ? 
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2 justify-between'>
                                <span className='font-bold'>Coupon Applied: {coupon.code}</span>
                                <Button text="Remove Code" color="red" size="xs" type="button" onClick={async () => { 
                                    setCoupon(null);
                                }} />
                            </div>
                            <span className='font-semibold text-sm'>{coupon?.description}</span>
                            <span className='text-xs mt-2'>After creating account, go to settings and billing to use coupon</span>
                        </div>
                        : 
                        <div className='flex items-center gap-3'>
                            <Field placeholder="Promo Code" name="coupon" className=' flex-1 rounded border border-slate-300 bg-slate-50 h-8 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 text-sm uppercase'></Field>
                            <Button text="Apply" color="pale" size="xs" type="button" onClick={() => searchCoupon(values.coupon)} />
                        </div>
                        }
                    </div>
                    <p className="mb-0 mt-4 text-center text-sm">
                        By signing up, you agree to our <a href="https://coflyt.com/tos" className='underline text-brand-electric' target="_blank">terms of service</a> and <a href="https://coflyt.com/privacy" className='underline text-brand-electric' target="_blank">privacy policy</a>
                    </p>          
                    <div className="flex justify-center items-center mt-10">
                        <div className='flex flex-col gap-8'>
                            <Button text="Let Get Started" color="" size="sm" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit" />
                            <div className='flex items-center gap-2'>
                                <h2>Have an account?</h2>
                                <Button text="Log In" color="" size="sm" onClick={()=> navigate('/auth')} type="button" />
                            </div>
                        </div>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateAccount;
