import { useEffect, useState } from 'react';
import { useSession } from 'contexts/SessionContext';
import { useMutation, useQuery } from '@apollo/client';
import { GetLogbooksDocument, GetLogbookDocument, GetLogEntryDocument, UpdateLogEntryDocument, GetUserInfoDocument } from 'graphql/generated';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/24/solid';
import Edit from '../Edit';
import { webFormat } from 'utils/statuses';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { formatApiDate } from 'utils/formatter';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
const View = function ({ id, closeFlyout: closeView } : { id: string, closeFlyout: () => void}) {
  const { data: { me } = { } } = useQuery(GetUserInfoDocument);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { data : { logEntry } = { }, refetch } = useQuery(GetLogEntryDocument, { variables: { logEntryId: id } });
  const [ showSign, setShowSign ] = useState(false);
  const [updateLogEntry] = useMutation(UpdateLogEntryDocument, {
    refetchQueries: [{ query: GetLogbookDocument, variables: { logBookId:id } }, { query: GetLogEntryDocument, variables: { logEntryId:id } }],
    onCompleted: () => setShowSign(false),
  });
  const navigate = useNavigate();
  const airmenNumber = me?.airmenLicense?.airmenNumber;
  return (
    <>
      {showSign && <div className="flex items-center justify-center fixed h-screen w-screen top-0 left-0 z-[100]">
        <div
        onClick={() => {
          setShowSign(false);
        }}
        className="bg-slate-100/50 backdrop-blur w-full h-full absolute">
          <div className="flex flex-col gap-2 items-center justify-center bg-white rounded border border-slate-300 relative top-1/3 left-[40%] hover-shadow max-w-md w-full">
            <XMarkIcon
              onClick={() => {
                setShowSign(false);
              }}
              className="h-4 w-4 absolute top-6 right-4 text-slate-500 cursor-pointer hover:text-slate-300 transition"
            />
            <div className="flex gap-3 items-center p-8">
              <div>
                {airmenNumber ? 
                  <div className=''>
                    <h1 className="text-sm mb-3">This aircraft, airframe, engine, propeller, or appliance identified was repaired and/or inspected in accordance with the current regulations of the Federal Aviation Administration and is approved for return to service for the work performed.</h1>
                    <h2 className="font-bold">License Number: {me?.airmenLicense?.airmenName} - {me?.airmenLicense?.licenseType}</h2>
                    <h2 className="font-bold">Signed: {me?.airmenLicense?.airmenNumber}</h2>
                    <h2 className="font-bold">Date: {formatApiDate(new Date())}</h2>
                    
                  </div>
                : <>
                    <h1 className="">To sign off on this entry you will first need to set up your license information on your profile.</h1>
                </> }
              </div>
            </div>
            <div className="flex w-full items-center justify-end gap-4 px-4 pb-2 pt-2 -mt-2 border-t border-slate-300 bg-slate-50">
              {/* <Button color="white" size="xs" text="Cancel" onClick={()=>{ cancel(false) }}/> */}
              <span
                className="text-sm cursor-pointer font-medium hover:opacity-70 transition"
                onClick={() => setShowSign(false)}>
                Cancel
              </span>
              {airmenNumber ? 
                <Button
                  color="navy"
                  size="xs"
                  text="Sign Off"
                  onClick={() => updateLogEntry({ variables: { input:{
                    id,
                    licenseInfo: me.airmenLicense,
                    signedOffAt: new Date(),
                    signedOffBy: me.firstName + ' ' + me.lastName,
                    signedUser: { connect: { id: me.id } }
                  } } })}
                />
                :
                <Button
                  color="navy"
                  size="xs"
                  text="Set Up Now"
                  onClick={() => navigate('/account/settings', { state: {settingsTab: 'Profile'}})}
                />
              }
            </div>
          </div>
        </div>
      </div> }
      <Flyout {...flyoutProps} />
      <div className="px-4">
        <Card>
          <div className="p-4 flex flex-col w-full gap-2">
            <div className='flex justify-between'>
              <h1 className="font-bold text-lg md:text-xl">{logEntry?.title}</h1>
              { !logEntry?.signedOffAt && <PencilIcon className='h-4 w-4 cursor-pointer' onClick={()=> updateFlyout({ title: 'Edit Log Entry', content: <Edit id={id} closeFlyout={(didDelete)=>{
                refetch();
                closeFlyout();
                if(didDelete){
                  closeView();
                }
              }}/> })} />} 
            </div>
            <h2 className=''><span className='font-semibold'>Description:</span> <div dangerouslySetInnerHTML={ { __html: logEntry?.description  } } /></h2>
            { logEntry?.systemsAffected?.length > 0 && <h3 className='text-sm mb-1'><span className='font-semibold'>JASC:</span> {logEntry?.systemsAffected.map((system, index) => 
              `${system.code}-${webFormat(system.name)}`
            ).join(', ')}</h3> }
            <h3 className='text-sm'><span className='font-semibold'>Date Performed:</span> {formatApiDate(logEntry?.logDate)}</h3>
            {logEntry?.signedOffAt && <h3 className='text-sm'><span className='font-semibold'>Date Signed Off:</span> {formatApiDate(logEntry?.signedOffAt)}</h3>}
            {logEntry?.signedOffBy && <h3 className='text-sm'><span className='font-semibold'>Signed By:</span> {logEntry?.signedOffBy}</h3>}
          </div>
        </Card>
        {logEntry?.intervalValues && Object.keys(logEntry?.intervalValues)?.length > 0 && <Card title='Intervals'>
          <div className='flex flex-wrap gap-4 ml-3'>
            { Object.keys(logEntry.intervalValues).map((key, index) => (
              <div className='flex flex-col gap-2 mr-4 text-center' key={index}>
                <span className='text-sm'>{key}</span>
                <span className='text-sm font-bold'>{logEntry.intervalValues[key]}</span>
              </div>
            ))}
          </div>
        </Card> }
        {logEntry?.attachments?.length > 0 && <Card className='w-full' title="Attachments">
            <div className='flex flex-wrap gap-4 ml-3'>
              {logEntry?.attachments?.map((file, index) => (
                <div className='flex flex-col gap-2' key={index}>
                  <a href={file.signedUrl} target="_blank" className='text-blue-500 underline'>{file.name}</a>
                </div>
              ))}
            </div>
        </Card> }
        { !logEntry?.signedOffAt && <div className="pt-3">
          <div className="flex justify-end gap-4">
            <Button
              text="Sign Log Entry"
              color="navy"
              onClick={() => setShowSign(true)}
              size="xs" /> 
          </div>
        </div> }
      </div>
    </>
  );
};

export default View;
