import { useMutation, useQuery } from '@apollo/client';
import {  
  CreateFlightPurposeDocument,
  CreateUserOrganizationProfileDocument,
  GetFlightPurposesDocument,
  GetUserOrganizationProfilesDocument,
  GetUserOrganizationRolesDocument,
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const NewUserSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string(),
    role: Yup.string().min(2).required("Required"),
  });

const NewUser = function ({ closeFlyout }: { closeFlyout: () => void }) {
    const { organizationId } = useSession();
    const [createUserOrganizationProfile] = useMutation(CreateUserOrganizationProfileDocument, { 
        refetchQueries: [{ query: GetUserOrganizationProfilesDocument, variables: { where: { organizationId: { equals: organizationId } } } }],
        onCompleted: () => {
            closeFlyout();
        }
    });
    const { data : { userOrganizationRoles } = {} } = useQuery( GetUserOrganizationRolesDocument, { variables: { where: { organizationId: { equals: organizationId }, sort: { not: null } } } } );
    const [ initialValues, setInitialValues ] = useState({ name : '', email: '', phone: '', role: '' });
    useEffect(() => {
        if(userOrganizationRoles) setInitialValues({ name : '', email: '', phone: '', role: userOrganizationRoles[0]?.id });
    }, [userOrganizationRoles]);
    return (
    <>
        <Formik enableReinitialize
        initialValues={initialValues}
        validationSchema={NewUserSchema}
        onSubmit={(values) => createUserOrganizationProfile({ variables: { input: { 
            nameInvite: values.name, 
            emailInvite: values.email, 
            phoneInvite: values.phone, 
            userOrganizationRole: { connect: { id: values.role } },
            organization: { connect: { id: organizationId } } } } }) }>
        {({ isSubmitting, values, setFieldValue, errors }) => {
            return (
                <Form>
                    <Card className="flex flex-col p-4 h-full m-4">
                        <FullFormikInput name="name" label="Member Name *" autoComplete="off" />
                        <FullFormikInput name="email" label="Member Email *" autoComplete="off" />
                        <FullFormikInput name="phone" label="Member Phone" autoComplete="off" />
                        <FullFormikSelect name="role" label="Role">
                        { 
                            userOrganizationRoles?.map((role) => {
                                return <option key={role.id} value={role.id}>{role.title}</option>
                            })
                        }
                        </FullFormikSelect>
                    </Card>
                    <div className="bg-gray-50 px-4 py-5 flex items-center justify-end">
                        <Button text="Grant Access" type='submit' size='xs' color='navy' />
                    </div>
                </Form>
        )}}
        </Formik>
    </>
    );
};

export default NewUser;
