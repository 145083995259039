import { useQuery } from "@apollo/client";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import { useSession } from "contexts";
import { GetFavoriteRoutesDocument } from "graphql/generated";
import { title } from "process";
import { useMemo } from "react";
import EditFavoriteRoute from "./Edit";
import Button from "components/Button/Button";
import { PencilIcon } from "@heroicons/react/24/solid";
import New from "../New";

export default function FavoriteRoutes() {
    const { organizationId } = useSession();
    const { loading, error, data : { favoriteRoutes } = {} } = useQuery(GetFavoriteRoutesDocument, { variables: { where: { 
        organizationId: { equals: organizationId },
      } } });
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const columns = useMemo(
    () => [
        {
            Header: 'Postion',
            className: 'text-left w-2/12',
            accessor: 'sort',
            Cell: ({ row, value }: any) => {
                return <span className="font-bold bg-slate-100 text-brand-dark rounded px-2 py-1 justify-center ml-4">{value+1}</span>;
            }
        },
        {
            Header: 'Name',
            className: 'text-left w-4/12',
            accessor: 'name',
        },
        {
            Header: 'Route',
            accessor: 'icaos',
            className: 'text-center w-5/12',
            Cell: ({ row, value }: any) => {
                return`${value?.[0]}->${value?.at(-1)}`;
            }
        },
        {
            Header: '',
            accessor: 'edit',
            className: 'text-center w-2/12',
            Cell: ({ row, value }: any) => {
                return <Button text="Edit" icon={<PencilIcon className="w-4 h-4 mr-2" />} size='xs' color='pale' onClick={(e) => { 
                    e.stopPropagation();
                    updateFlyout( {title: row.original.name, content: <EditFavoriteRoute id={row.original.id} closeFlyout={closeFlyout} />} );
                }}/>
            }
        },
    ],
    []
    );
    return (
        <>
            <Flyout {...flyoutProps} />
            <Table columns={columns} data={[...favoriteRoutes ?? []]?.sort((a,b) => a.sort-b.sort )}
            onRowClick={(row: any) => { 
                updateFlyout( {title: row.name, content: <New icaos={row?.icaos?.map(icao => {
                    return {icao: icao,
                        id: row?.airports?.find(airport => airport.icao === icao)?.id
                    };
                })} closeFlyout={closeFlyout} />} );
            } } />
        </>
    )
}