import { Fragment, useEffect } from 'react';
import { ArrowLeftIcon } from '../../../assets/icons';
import logo from '../../../assets/icons/logo_navbar_w.svg';
import { useQuery } from '@apollo/client';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSession } from 'contexts';
import { GetOrganizationsDocument } from 'graphql/generated';
import { OrgTypes } from 'utils/orgTypes';
import UserMenu from './UserMenu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BarTop(props) {
  const location = useLocation();
  const { organizationId, setOrganizationId, user, organizationType } = useSession();
  const { data: { organizations } = { organizations: [] } } = useQuery(GetOrganizationsDocument, { variables: { where: { userOrganizationProfiles: { 
    some: { user: { is:{ id: { equals: user?.id } } } } 
   } } } });
 
  useEffect(() => {
    if (!organizationId && organizations && organizations.length) {
      setOrganizationId(organizations[0].id);
    }
  }, [organizationId, organizations, setOrganizationId]);
  return (
    <>
      <div className="flex sticky flex-col top-0 w-screen h-auto z-40 md:z-[60] border-b border-slate-300 shadow-blue">
        {/* Top Bar Mobile */}
        <div className="md:hidden bg-brand-dark w-full">
          {props.context == null || !location.pathname.includes("/aircraft") || Object.keys(props.context).length === 0 ? (
            <div className="flex items-center justify-between h-20 px-4 py-2 w-full">
              <Link to={organizationType === OrgTypes.PART145 ? '/mro/dashboard' : '/crafts'} className={`h-10`}>
                <img src={logo} className="h-10" alt=""/>
              </Link>
              <UserMenu />
            </div>
          ) : (
            <div className="flex items-center h-20 px-4 py-2 w-full">
              
              <Link to={location.pathname === "/aircraft"?"/crafts":"/aircraft"}><ArrowLeftIcon className={` h-4 w-4 ml-1 mr-4 text-white`} aria-hidden="true" /></Link>
              
              <div className="text-white">
                <h1 className="text-xl font-semibold -mb-1">{props.context.line1}</h1>
                <h2>{props.context.line2}</h2>
              </div>
            </div>
          )}
        </div>
        
      </div>
    </>
  );
}
