import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { BriefcaseIcon, PlusIcon } from '@heroicons/react/24/solid';
import FlyoutMini from 'components/FlyoutMini/FlyoutMini';
import { Flyout, useFlyout } from 'components/Flyout/Flyout';
import UserIcon from 'components/UserIcon/UserIcon';
import StatusButton from 'components/StatusButton/StatusButton';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroWorkOrderDocument, UpdateMroWorkOrderDocument, UpdateWoStatusDocument } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import Overview from './WorkOrderTabs/Overview';
import Billing from './WorkOrderTabs/Estimates';
import Request from './WorkOrderTabs/Request';
import NewTemplateFlyout from './Components/NewTemplate';
import Toast, { useToast } from 'components/Toast/Toast';
import Logs from './WorkOrderTabs/Logbooks';
import useParamState from 'hooks/useParamState';
import TabCards, { TabCardItem } from 'components/TabCards/TabCards';
import { AbilityContext } from 'contexts/AbilityContext';
import WorkOrderNumber from 'components/WorkOrderNumber/WorkOrderNumber';
import Estimate from './WorkOrderTabs/Estimates';
import Invoice from './WorkOrderTabs/Invoices';
import BillableItemsFlyout from './WorkOrderTabs/Billing';
import BillableItems from './WorkOrderTabs/Billing';
import EditAssignees from './Edit/Components/EditAssignees';
import Intervals from './WorkOrderTabs/Intervals';

const WorkOrder = function () {
  const ability = useContext(AbilityContext);
  const { toastProps } = useToast();
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const { flyoutProps: flyoutMiniProps, updateFlyout: updateFlyoutMini } = useFlyout();
  const { workOrderId } = useParams();
  const { data: { mroWorkOrder } = {} } = useQuery(GetMroWorkOrderDocument, {
    pollInterval: 300000, // 5 minutes
    variables: { id: workOrderId },
  });
  const [updateWorkOrderStatus] = useMutation(UpdateWoStatusDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: workOrderId } }],
  });
  const [updateWorkOrder] = useMutation(UpdateMroWorkOrderDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: workOrderId } }],
  });
  const tabs : TabCardItem[] = [
    {
      title: 'Overview',
      content: ( <Overview mroWorkOrder={mroWorkOrder} updateFlyoutMini={updateFlyoutMini} updateFlyout={updateFlyout} closeFlyout={closeFlyout} /> ),
    },
    {
      title: 'Billable',
      content: ( <BillableItems workOrderId={mroWorkOrder?.id} /> ),
      hidden: ability.cannot("Manage", "Billing")
    },
    {
      title: 'Estimates',
      content: ( <Estimate mroWorkOrder={mroWorkOrder} updateFlyoutMini={updateFlyoutMini} updateFlyout={updateFlyout} closeFlyout={closeFlyout} /> ),
      hidden: ability.cannot("Manage", "Billing")
    },
    {
      title: 'Invoices',
      content: ( <Invoice mroWorkOrder={mroWorkOrder} updateFlyoutMini={updateFlyoutMini} updateFlyout={updateFlyout} closeFlyout={closeFlyout} /> ),
      hidden: ability.cannot("Manage", "Billing")
    },
    {
      title: 'Intervals',
      content: ( <Intervals workOrderId={workOrderId} /> ),
      hidden: ability.cannot("Manage", "Log Entries")
    },
    {
      title: 'Logs',
      content: ( <Logs mroWorkOrder={mroWorkOrder} updateFlyoutMini={updateFlyoutMini} updateFlyout={updateFlyout} closeFlyout={closeFlyout} /> ),
      hidden: ability.cannot("Manage", "Log Entries")
    },
  ];
  const [currentTab, setCurrentTab] = useParamState('tab', 'Overview');
  const showEditAssignees = () => {
    updateFlyout({
      title: 'Update Assignees',
      content: (
        <EditAssignees
          selectedAssignees={mroWorkOrder?.assignees}
          setSelectedAssignees={(newAssignees) => {
            const notAssigneed = [];
            mroWorkOrder?.allAssignees.forEach((assignee) => {
              if (!newAssignees?.find((newAssignee) => assignee.id === newAssignee.id)) {
                notAssigneed.push({ id: assignee.id });
              }
            });
            updateWorkOrder({
              variables: {
                input: {
                  id: workOrderId,
                  assignees: {
                    connect: newAssignees.map((assignee) => {
                      return { id: assignee.id };
                    }),
                    disconnect: notAssigneed,
                  },
                },
              },
            });
          }}
          closeFlyout={closeFlyout}
        />
      ),
    });
  };
  const color = mroWorkOrder?.mroWorkOrderCategory?.color ? "text-[" + mroWorkOrder?.mroWorkOrderCategory?.color + "]" : 'text-slate-500';
  return (
    <>
      <Toast {...toastProps} />
      <FlyoutMini {...flyoutMiniProps} />
      <Flyout {...flyoutProps} />
      <div className="bg-white border rounded border-slate-300 shadow-blue">
        {/* HEADER SECTION */}
        <div className="flex flex-col">
          <div className="flex rounded-t items-center justify-between p-6">
            <div className="flex items-center gap-2">
              <BriefcaseIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">{mroWorkOrder?.title}</h1>
              <div className='flex flex-col justify-center mb-[-10px]'>
                <WorkOrderNumber code={mroWorkOrder?.mroWorkOrderCategory?.code} workOrderNum={mroWorkOrder?.workOrderNum} />
                <span className={`text-xs p-0.5 ${ color } font-semibold text-center`}>{mroWorkOrder?.mroWorkOrderCategory?.name}</span>
              </div>
            </div>
            <div className="flex items-stretch">
              <StatusButton
                readOnly={false}
                context="order"
                text={mroWorkOrder?.status}
                className=""
                onSelect={(status) =>
                  updateWorkOrderStatus({
                    variables: {
                      id: workOrderId,
                      status,
                    },
                  })
                }
              />
              <Menu
                as="div"
                className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                <Menu.Button className="">
                  <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="edit">
                          <button
                            className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                            onClick={() => {}}>
                            Edit
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                          onClick={() => {
                            updateFlyout({
                              title: 'Create Work Order Template',
                              content: (
                                <NewTemplateFlyout
                                  titleTemplate={mroWorkOrder?.title}
                                  closeFlyout={closeFlyout}
                                  workOrderId={mroWorkOrder?.id}
                                />
                              ),
                            });
                          }}>
                          Create Template
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="flex justify-between items-end p-4 px-6 bg-slate-50 border-slate-300 border-t rounded-b w-full overflow-hidden">
            <ul className="flex">
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.tailNumber}</h3>
                <span className="text-xs text-slate-500">TAIL #</span>
              </li>
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.year}</h3>
                <span className="text-xs text-slate-500">YEAR</span>
              </li>
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.make}</h3>
                <span className="text-xs text-slate-500">MAKE</span>
              </li>
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroWorkOrder?.mroCraft?.model}</h3>
                <span className="text-xs text-slate-500">MODEL</span>
              </li>
            </ul>
            <div className="flex">
              <div className="flex-col">
                <button className="flex w-full justify-center -mr-[2px]"
                  onClick={showEditAssignees}>
                   {mroWorkOrder?.assignees.length === 0 ? (
                      <div className="flex items-center self-center justify-center h-7 w-7 bg-brand-pale text-brand-electric rounded-full border border-dashed border-brand-electric cursor-pointer transition-all relative mb-0.5 hover:text-white hover:bg-brand-electric hover:border-solid">
                        <PlusIcon className="h-4 w-4" />
                      </div>
                    ) : (
                      mroWorkOrder?.assignees.map((item, index) => {
                        return (
                          <UserIcon
                            key={index}
                            iconSize="sm"
                            iconText={item.firstName + ' ' + item.lastName}
                            iconId={item.firstName + ' ' + item.lastName}
                            iconColor={item.profileColor}
                            className="border-2 border-slate-50"
                          />
                        );
                      })
                    )}
                </button>
                <span className="text-xs text-slate-500 w-full text-right block">ASSIGNEES</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-2">
        <TabCards items={tabs} activeTitle={currentTab} setActiveTitle={setCurrentTab} addBars={false} />
      </div>
    </>
  );
};

export default WorkOrder;
