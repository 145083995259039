import { useEffect, useLayoutEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PlusIcon, ExportIcon } from 'assets/icons';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import View from './View';
import New from './New';
import { GetCraftsReservationWithExportDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { applyTimezone, formatCalendarTimeUTC } from 'utils/formatter';
import Calendar from 'components/Calendar/Calendar';
import Toast, { useToast } from 'components/Toast/Toast';
import List from './Components/List';
import Button from 'components/Button/Button';
import Tabs from 'components/Tabs/Tabs';
import { OrgSubtypes } from 'utils/orgTypes';
import Card from 'components/Card/Card';
import ScreenShot from 'assets/images/reservation.jpg';
import { useNavigate } from 'react-router-dom';
import isMobileApp from 'utils/isMobileApp';
        
let viewTabs = ["Calendar", "List"];
const Reservations = () => {
  const [events, setEvents] = useState([]);
  const [size, setSize] = useState([0, 0]);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const navigate = useNavigate();
  const [viewTab, setViewTab] = useState("Calendar");
  const { toastProps, showToast } = useToast();
  const { organizationId, organizationSubtype, user } = useSession();
  const { loading, error, data, refetch } = useQuery(GetCraftsReservationWithExportDocument, {
    variables: { organizationId },
  });
  useLayoutEffect(() => {
    function updateSize() {
      if(window.innerWidth<520){
        setViewTab("List");
      }
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

 
  useEffect(()=>{
    const craftEvents = []; 
    data?.crafts?.forEach((craft)=>craft.reservations.forEach((res)=>
      craftEvents.push({ ...res, 
        userId: res.pilot?.user?.id , name:` ${formatCalendarTimeUTC(applyTimezone(res?.from, Number(res?.timezone), Number(user.timezone)).toISOString())} ${res?.pilot?.user?.firstName} ${res.pilot?.user?.lastName}\n${craft.name}-${craft.tailNumber}`
    })));
    setEvents(craftEvents.sort((a, b) =>{
      const aDate = new Date(a.from);
      const bDate = new Date(b.from);
      return (+bDate) - (+aDate);
    }));
  }, [data]);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  
  const showView = (reservation)=> {
    updateFlyout({ title: 'Reservation', content: <View refreshData={refetch} id={reservation?.id} closeView={closeFlyout} /> });
  }
  
  return (
    <>
      {/* TOAST */}
      <Toast {...toastProps} />
      <Flyout {...flyoutProps} />
      <div>
        <div className="flex flex-col">
          {/* Title */}
          <div className="flex-rows items-center bg-white border border-slate-300 rounded shadow-blue mb-4">
            <div className="flex justify-between items-center p-6 gap-4 pb-3">
              <div className="flex items-center">
                <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Reservation</h1>
              </div>
              { organizationSubtype === OrgSubtypes.MULTI &&
              <div className="flex justify-end gap-2 flex-wrap">
                <a href={data?.organization?.reservationsCalendarLink}><Button text="Export" color="navy" size="xs" icon={ <ExportIcon className="h-3 w-3 mr-2" /> } onClick={ () => {} } /></a>
                <Button text="Add Reservation" color="navy" size="xs" icon={ <PlusIcon className="h-3 w-3 mr-2" /> } onClick={ () => updateFlyout({ title: 'New Reservation', content: <New closeFlyout={()=>{closeFlyout(); refetch();}} /> }) } />
              </div> }
            </div>
            { organizationSubtype === OrgSubtypes.MULTI && size[0] > 520 &&
            <div className="px-6 bg-slate-50 border-y border-slate-200 gap-3">
              <Tabs items={viewTabs} activeItem={viewTab} setActiveItem={setViewTab} />
            </div> }
          </div>
        </div>
        {/* Reservation List/Calendar */}
        <>
        { organizationSubtype !== OrgSubtypes.MULTI ? <div>
            {isMobileApp() ?  
              <Card>
                <h1 className="text-center text-2xl font-bold text-brand-dark">This Feature is not available for your organization</h1>
              </Card>
            :
            <Card>
              <h1 className="text-center text-3xl font-bold text-brand-dark">Try Our Multi-Pilot Plan</h1>
              <h2 className="text-center text-xl font-semibold text-slate-400">Flight scheduling is available on our multi pilot plan.</h2>
              <div className='flex flex-col justify-center my-8 gap-7'>
                <img className="max-h-[26rem] mx-auto" src={ScreenShot} alt="" />
                <Button text="UPGRADE NOW" color="navy" size="sm" onClick={ () => navigate('/account/settings?settingsTab=Billing') } />
              </div>
            </Card>}
          </div> : 
          <div>
            { viewTab === "Calendar" ? <Calendar crafts={data.crafts} events={events} onEventSelected={showView} /> 
            : 
            <List rowClicked={showView} data={events} /> }
          </div>
        }
        </>
        
      </div>
    </>
  );
};

export default Reservations;
