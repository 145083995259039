import { ChangeEventHandler, useMemo, useState, useEffect } from 'react';
import {
  GetMroInvoiceDocument,
  GetMroInvoiceQuery,
  GetMroWorkOrderQuery,
  GetWorkOrderBillingDocument,
  MroInvoiceCreateInput,
  UpdateMroInvoiceDocument,
} from 'graphql/generated';
import type { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { addDays } from 'date-fns';
import { useMutation } from '@apollo/client';
import Invoice from '../partials/InvoiceView';

export default function EditInvoiceFlyout({
  workOrder,
  invoice,
  closeFlyout,
}: {
  workOrder: GetMroWorkOrderQuery['mroWorkOrder'];
  invoice: GetMroInvoiceQuery['mroInvoice'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
}) {
  const initialVals = useMemo(() => {
    const init = {
      invoiceNumber: invoice.invoiceNumber,
      invoiceDate: new Date(invoice.invoiceDate).toISOString(),
      dueDate: new Date(invoice.dueDate).toISOString(),
      notes: invoice.notes,
      items: [
        ...workOrder.mroWorkOrderItems
          .filter((item) => !item.parentId && item.status !== 'ABORT')
          .map((item) => {
            const partsCost = Number(item.mroWorkOrderParts.reduce((acc, part) => acc + part.unitPrice * part.quantity, 0));
            const laborCost = Number(item.isFlatRate ? item.flatRate : item.totalLaborBillable);
            return {
              id: item.id,
              discrepancy: item.title,
              laborHours: item.totalLaborHours,
              laborCost,
              partsCost,
              active: invoice.items.find((invoiceItem) => invoiceItem.id === item.id),
              isFlatRate: item.isFlatRate,
              subTotal: laborCost + partsCost,
              model: item.category?.mroComponent?.model,
              serialNumber: item.category?.mroComponent?.serialNumber,
              parts: item.mroWorkOrderParts
              .map((part) => {
                return {
                  quantity: part.quantity,
                  partNumber: part?.mroPart?.partNumber ?? part?.partNumber,
                  description: part?.mroPart?.description ?? part?.partDescription,
                  unitPrice: part.unitPrice,
                  subtotal: part.unitPrice * part.quantity,
                };
              }),
            };
          }),
        ...invoice.items
          .filter((item) => item.temp)
          .map((item) => {
            return { ...item, legacy: true };
          }),
      ],
      charges: invoice.charges ?? [],
      discounts: invoice.discounts ?? [],
      laborSummary: invoice.laborSummary,
      priceSummary: invoice.priceSummary,
      id: invoice.id,
    };
    return init;
  }, [workOrder]);
  const [invoiceItems, setInvoiceItems] = useState<(typeof initialVals.items[number] & { id?: string | number; notes?: string })[]>(
    initialVals.items
  );
  const [taxes, setTaxes] = useState(initialVals.priceSummary.tax);
  const [charges, setCharges] = useState<typeof initialVals.charges>(initialVals.charges);
  const [discounts, setDiscounts] = useState<typeof initialVals.discounts>(initialVals.discounts);

  const [vals, setVals] = useState({
    invoiceNumber: initialVals.invoiceNumber,
    invoiceDate: initialVals.invoiceDate,
    dueDate: initialVals.dueDate,

    totalShipping: initialVals.priceSummary.totalShipping,
    totalParts: initialVals.priceSummary.totalParts,
    totalLabor: initialVals.priceSummary.totalLabor,
    discount: initialVals.priceSummary.discount,
    totalHours: initialVals.laborSummary.totalHours,
    totalCharges: Math.round(initialVals.charges.reduce((acc, charge) => acc + charge.total, 0) * 100) / 100,
    total: initialVals.priceSummary.amountDue,

    notes: initialVals.notes,
  });

  const [updateInvoice] = useMutation(UpdateMroInvoiceDocument, {
    refetchQueries: [GetWorkOrderBillingDocument, { query: GetMroInvoiceDocument, variables: { mroInvoiceId: invoice.id } }],
  });

  const handleSubmit = () => {
    const newVals: typeof initialVals & { amount?: number; status?: string; mroWorkOrder?: MroInvoiceCreateInput['mroWorkOrder'] } = {
      ...initialVals,
    };
    const activeItems = invoiceItems.filter((item) => item.active);
    const laborFlat = Math.round(activeItems.filter((item) => item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;
    const laborTM = Math.round(activeItems.filter((item) => !item.isFlatRate).reduce((acc, item) => acc + item.laborCost * 100, 0)) / 100;

    // const totalCharges = Math.round(charges.reduce((acc, cur) => acc + cur.total, 0) * 100) / 100;
    newVals.invoiceNumber = vals.invoiceNumber;
    newVals.invoiceDate = vals.invoiceDate;
    newVals.dueDate = addDays(new Date(vals.invoiceDate), 7).toISOString();
    newVals.items = activeItems;
    newVals.priceSummary = {
      amountDue: vals.total,
      deposit: initialVals.priceSummary.deposit,
      tax: Math.round((vals.totalLabor + initialVals.priceSummary.totalParts + vals.totalCharges) * taxes * 100) / 100,
      taxRate: taxes,
      totalCharges: vals.totalCharges,
      laborFlat: laborFlat,
      laborTM: laborTM,
      totalLabor: vals.totalLabor,
      totalParts: vals.totalParts,
      totalShipping: vals.totalShipping,
      discount: vals.discount,
    };
    newVals.laborSummary = {
      shopLabor: vals.totalLabor,
      outsideLabor: 0,
      totalHours: vals.totalHours,
    };
    newVals.amount = vals.total;
    newVals.mroWorkOrder = { connect: { id: workOrder.id } };
    newVals.charges = charges;
    newVals.notes = vals.notes;

    updateInvoice({ variables: { input: newVals } })
      .then(() => closeFlyout())
      .catch(console.error);
  };

  return (
    <div className="flex flex-col p-4 h-full">
      <Invoice
        invoiceItems={invoiceItems}
        edit={true}
        invoice={vals}
        charges={charges}
        discounts={discounts}
        location={workOrder.mroLocation.code}
        setCharges={setCharges}
        {...{ invoiceItems, setInvoiceItems, setCharges, charges, taxes, setTaxes, vals, setVals, setDiscounts, discounts }}
      />

      <div className="flex gap-2 items-center justify-end mt-2 pb-20">
        <button
          onClick={() => closeFlyout()}
          className="flex items-center font-semibold text-slate-500 border border-slate-500 cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="flex items-center font-semibold bg-brand text-white border border-brand cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
          Save Changes
        </button>
      </div>
    </div>
  );
}
