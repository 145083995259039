import { createContext, useState, useEffect } from 'react';
import JascSelect from 'components/JascCategories/JascSelect';
import LoginForm from './Steps/Login';
import TailLookup from './Steps/TailLookup';
import SignupForm from './Steps/SignupForm';
import ContactInfo from './Steps/ContactInfo';
import Issues from './Steps/Issues';
import Confirm from './Steps/Confirm';
import CraftInfo from './Steps/CraftInfo';
import CraftSelect from './Steps/CraftSelect';
import { contactValues } from './Steps/ContactInfo';
import MroLogo from 'assets/temp/demo_light.png';
import Logo from 'assets/icons/logo_navbar_w.svg';
import LogoAlt from 'assets/icons/logo_navbar.svg';
import { GetMroOrganizationInfoDocument, GetServiceUserDocument, LoginForServiceMutation, LookupTailQuery, OnboardCraftInput } from 'graphql/generated';
import { Link, useNavigate } from 'react-router-dom';
import Completed from './Steps/Completed';
import { useQuery } from '@apollo/client';
import { useSession } from 'contexts';
import { useIntercom } from 'react-use-intercom';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import SelectShop from './Steps/SelectShop';

interface ServiceFormContextInterface {
  tailNumber: string;
  setTailNumber: React.Dispatch<React.SetStateAction<string>>;
  tailInfo: LookupTailQuery['registrations'][number];
  setTailInfo: React.Dispatch<React.SetStateAction<LookupTailQuery['registrations'][number]>>;
  craftId: string;
  setCraftId: React.Dispatch<React.SetStateAction<string>>;
  user: LoginForServiceMutation['login']['user'];
  setUser: React.Dispatch<React.SetStateAction<LoginForServiceMutation['login']['user']>>;
  organization: any;
  setOrganization: React.Dispatch<React.SetStateAction<any>>;
  mroOrganization: any;
  setMroOrganization: React.Dispatch<React.SetStateAction<any>>;
  formStep: string;
  changeStep: any;
  prevStep: string[];
  // setFormStep: React.Dispatch<React.SetStateAction<string>>;
  customerInput: typeof contactValues;
  setCustomerInput: React.Dispatch<React.SetStateAction<typeof contactValues>>;
  craftInput: OnboardCraftInput;
  setCraftInput: React.Dispatch<React.SetStateAction<OnboardCraftInput>>;
  issuesInput: { description: string; service: string; otherService: string; location: string; otherLocation?: string };
  setIssuesInput: React.Dispatch<React.SetStateAction<{ description: string; service: string; otherService: string; location: string; otherLocation?: string }>>;
  resetState: (forceReset?: boolean ) => void;
  markAsComplete: () => void;
}

export const getMroCustomer = (
  organization: LoginForServiceMutation['login']['user']['organizations'][number],
  mroOrganizationId: string
) => {
  return organization?.mroCustomers.find((mroCustomer) => mroCustomer?.mroOrganization?.id === mroOrganizationId);
};

export const ServiceFormContext = createContext<ServiceFormContextInterface | null>(null);
const fieldCss =
  'rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-4 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm';

const getStep = (step: string) => {
  switch (step) {
    case 'login':
      return <LoginForm ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} />;
    case 'craftInfo':
      return <CraftInfo ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} />;
    case 'craftSelect':
      return <CraftSelect ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} />;
    case 'signup':
      return <SignupForm ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} />;
    case 'tailLookup':
      return <TailLookup fieldCss={fieldCss} />;
    case 'contactInfo':
      return <ContactInfo ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} />;
    case 'issues':
      return <Issues ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} JascSelect={JascSelect} />;
    case 'confirm':
      return <Confirm />;
    case 'completed':
      return <Completed />;
    default:
      return <SelectShop ServiceFormContext={ServiceFormContext} fieldCss={fieldCss} />;
  }
};

const CustomerServiceRequest = (props) => {
  const { user: loggedInUser } = useSession();
  const { data: { me } = {} } = useQuery(GetServiceUserDocument, { skip: !loggedInUser});
  const [user, setUser] = useState<ServiceFormContextInterface['user'] | undefined>();
  const [organization, setOrganization] = useState<ServiceFormContextInterface['organization'] | undefined>();
  const [craftId, setCraftId] = useState<string | undefined>();
  const [tailNumber, setTailNumber] = useState<string | undefined>();
  const [issuesInput, setIssuesInput] = useState({ description: '', service: '', otherService: '', location: 'JQF' });
  const [formStep, setFormStep] = useState('selectShop');
  const [prevStep, setPrevStep] = useState(['selectShop']);
  const [customerInput, setCustomerInput] = useState<any>(contactValues);
  const [tailInfo, setTailInfo] = useState<LookupTailQuery['registrations'][number] | undefined>();
  const [craftInput, setCraftInput] = useState<OnboardCraftInput>();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const defPath = '/mro/service-request';
  const mroOrganizationId = urlParams.get('orgId') ? urlParams.get('orgId') : '';
  const { data: { mroOrganizationInfo } = {} } = useQuery(GetMroOrganizationInfoDocument, { variables: { id: mroOrganizationId } } );
  const [ mroOrganization, setMroOrganization ] = useState<any>();
  const orgName = mroOrganization?.name;
  // useEffect(() => {
  //   if(!contextValue ) {return}
  //   setFormStep(prevStep[prevStep.length-1]);
  // }, [prevStep])
  const { shutdown } = useIntercom();
  shutdown();

  useEffect(() => {
    setMroOrganization(mroOrganizationInfo);
  }, [mroOrganizationInfo]);

  useEffect(() => {
    window.onpopstate = (e) => {
      setFormStep(prevStep[prevStep.length - 1]);
      let newArr = prevStep.slice(0, -1);
      setPrevStep(newArr);
      if (!prevStep.length) {
        setUser(undefined);
        navigate(defPath);
      }
    };
  });

  useEffect(() => {
    if (me && me?.userOrganizationProfiles?.[0]?.organization) {
      setUser(me);
      setOrganization(me?.userOrganizationProfiles?.[0]?.organization);
    }
    if (me && me?.userOrganizationProfiles?.[0]?.organization && mroOrganization) {
      setFormStep('craftSelect');
    }
  }, [me]);


  const changeStep = function (prev, next, backwards) {
    if (next) {
      setFormStep(next);
      navigate(defPath + '?step=' + prevStep.length + '&orgId=' + mroOrganizationId);
    }
    if (backwards) {
      setFormStep(prevStep[prevStep.length - 1]);
      let newArr = prevStep.slice(0, -1);
      setPrevStep(newArr);
    } else {
      setPrevStep([...prevStep, prev]);
    }
  };

  const resetState = (forceReset = false) => {
    if(!loggedInUser || forceReset){
      setUser(undefined);
      setOrganization(undefined);
      setFormStep('selectShop');
    }else{
      setFormStep('craftSelect');
    }
    
    setCraftId(undefined);
    setTailNumber(undefined);
    setIssuesInput({ description: '', service: '', otherService: '', location: '' });
    setPrevStep(['selectShop']);
    setCustomerInput(contactValues);
    setTailInfo(undefined);
  };

  const markAsComplete = () => {
    setPrevStep(['selectShop']);
  };

  const contextValue = {
    tailNumber,
    setTailNumber,
    craftId,
    setCraftId,
    user,
    setUser,
    formStep,
    changeStep,
    prevStep,
    customerInput,
    setCustomerInput,
    issuesInput,
    setIssuesInput,
    tailInfo,
    setTailInfo,
    organization,
    setOrganization,
    mroOrganization,
    setMroOrganization,
    resetState,
    craftInput,
    setCraftInput,
    markAsComplete,
  };
  return (
    <ServiceFormContext.Provider value={contextValue}>
      <div className="flex flex-col md:flex-row items-center md:items-stretch justify-center bg-white md:bg-white/0 md:max-w-6xl w-full md:min-h-[768px] md:shadow md:mx-6 relative">
        {loggedInUser && <div className='absolute -top-10 w-full px-3 py-2 bg-brand-dark rounded-t flex items-center gap-1'>
          <ArrowLeftIcon className="h-4 w-4 text-white cursor-pointer" onClick={() => navigate('/aircraft/requests')} />
          <a href='/aircraft/requests' className="w-full text-lg underline text-white">{"Back to Coflyt App"}</a>
        </div> }
        <div className="w-1/3 hidden md:flex flex-col bg-brand-dark relative rounded-l">
          <div className="flex justify-center items-center p-8">
            {mroOrganizationInfo?.icon && <img className="max-h-[4rem] pr-8" src={mroOrganizationInfo?.icon} alt="" /> }
            <img className="max-h-[2.5rem] w-1/2 border-l border-white pl-8" src={Logo} alt="" />
          </div>
          <div className="flex flex-col">
            <h1
              className="rounded-tl text-white text-center text-2xl font-bold pl-10 pr-8 py-4 -ml-2 bg-brand relative border-b-2 border-[#162551]"
              id="ribbon">
              {orgName}
            </h1>
            <p className="text-brand-pale font-medium my-8 px-8">
              Our customer portal allows you to manage the status of your aircraft and maintenance.
            </p>
            <span className="text-brand-pale font-medium px-8">
              Need additional help? <Link target="_blank" rel="noreferrer" to='https://coflyt.com/contact-us' className="font-bold text-white underline">Contact Us.</Link>
            </span>
          </div>
          <span className="text-brand-pale absolute bottom-8 left-0 w-full text-center text-xs">
            © {new Date().getFullYear()} Coflyt. All rights reserved.
          </span>
        </div>
        <div className="flex items-center justify-center md:w-2/3 rounded md:p-16 bg-white rounded-r mb-8 md:mb-0">{getStep(formStep)}</div>
        <div className={`${formStep !== 'tailLookup' ? 'hidden' : 'flex'} md:hidden justify-center items-center absolute bottom-4`}>
          <img className="h-10 pr-8 invert" src={MroLogo} alt="" />
          <img className="h-8 border-l border-brand pl-8 w-1/2" src={LogoAlt} alt="" />
        </div>
      </div>
    </ServiceFormContext.Provider>
  );
};

export default CustomerServiceRequest;
