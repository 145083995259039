import { useEffect, useState } from 'react';
import { useSession } from 'contexts/SessionContext';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { GetLogbooksDocument, LogEntryCreateInput, CreateLogEntryDocument, GetLogbookDocument, GetComponentsDocument, GetIntervalsForCraftDocument } from 'graphql/generated';
import DatePicker from 'components/DatePicker/DatePicker';
import Card from 'components/Card/Card';
import { FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import FilePicker from 'components/FilePicker/FilePicker';
import JascSelect from 'components/JascCategories/JascSelect';
import ReactQuill from 'react-quill';
const New = function ({ id, closeFlyout } : { id: string, closeFlyout: () => void}) {
  const { craftId } = useSession();
  const { data : { logBook } = { } } = useQuery(GetLogbookDocument, { variables: { logBookId: id } });
  const [createLogEntry] = useMutation(CreateLogEntryDocument, {
    refetchQueries: [{ query: GetLogbooksDocument, variables: { craftId: craftId } }, { query: GetLogbookDocument, variables: { logBookId:id } }],
    onCompleted: () => closeFlyout(),
  });
  const [files, setFiles] = useState<File[]>([]);
  const [prefill, setPrefill] = useState({
    logDate: new Date().setHours(0, 0, 0, 0),
    title: '',
    description: '',
    intervals: [],
    systemsAffected: [],
  });
  useEffect(() => {
    if(logBook?.intervals?.length > 0){
      setPrefill((prev) => ({ ...prev, intervals: logBook.intervals?.map((interval) => ({ name: interval?.name, value: interval?.recentIntervalLog?.endValue ?? 0 })) }));
    }
  }, [logBook]);
  const handleSubmit = async (values: typeof prefill, { setSubmitting }: FormikHelpers<typeof prefill>) => {
    const entryInput: LogEntryCreateInput = {
      logDate: values.logDate,
      title: values.title,
      description: values.description,
      logBook: { connect: { id } },
      systemsAffected: { connect: values.systemsAffected.map((system) => ({ id: system.split('/')[0] })) },
      intervalValues: values.intervals.reduce((acc, interval) => {
        acc[interval.name] = interval.value;
        return acc;
      }, {})
    };
    const logEntry = await createLogEntry({ variables: { input: entryInput } });
    if (files) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('logEntryId', logEntry?.data?.createLogEntry?.id);
      const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
    }
    setSubmitting(false);
    setPrefill({ ...prefill });
  };

  return (
    <div className="px-4">
      <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
        {({setFieldValue, values}) => (
          <Form className="">
            <Card>
              <dl>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
                </div>
                <div className="flex justify-between items-center p-4">
                  <label htmlFor="logDate" className="w-1/3 text-slate-500">
                    Date
                  </label>
                  <Field as={DatePicker} name="logDate" id="logDate" className="w-2/3 rounded border px-2 border-slate-300 text-sm" />
                </div>
                <div className="p-4 flex items-center justify-center">
                  <div className='w-1/3 mb-[-15px]'>
                    <dt className="text-sm font-medium text-slate-500">Systems Affected</dt>
                    <a href='https://sdrs.faa.gov/documents/JASC_Code.pdf' target='_' className="text-xs font-medium text-slate-500 underline">(JASC Table And Definitions)</a>
                  </div>
                  <Field
                    as={JascSelect}
                    name="systemsAffected"
                    className="w-2/3"
                    onChange={(value) => {
                      setFieldValue('systemsAffected', value);
                    }}>
                    <option>1230 - Hydraulic Fuel servicing</option>
                  </Field>
                </div>
              </dl>
            </Card>
            <Card title='Description' >
              <div>
                <ReactQuill theme="snow" value={values.description} onChange={(value) => setFieldValue('description', value)} />
              </div>
            </Card>
            <Card title='Intervals'>
              <div className='w-full flex gap-3 justify-around flex-wrap'>
                {logBook?.intervals?.map((interval, index) => (
                  <div className="flex flex-col gap-2" key={interval.id}>
                    <h3 className="text-slate-500">{interval.name}</h3>
                    <input value={ values?.intervals?.[index]?.value} onChange={ (e) => {
                      const newIntervals = values?.intervals?.map((int, i) => {
                        if(i === index){
                          return { ...int, value: e.target.value };
                        }
                        return int;
                      });
                      setFieldValue('intervals', newIntervals);
                    }} 
                    className="max-w-sm rounded bg-slate-50 border-slate-200 border text-brand focus:outline-0 px-3 py-2 placeholder:text-slate-400 placeholder:text-sm" />               
                  </div>
                ))}
              </div>
            </Card>
            <Card title='Attachments' >
              <FilePicker files={files} setFiles={setFiles} />
            </Card>
            <div className="pt-3 pb-20">
              <div className="flex justify-end gap-4">
                <Button
                  text="Cancel"
                  color="pale"
                  size="xs"
                  onClick={closeFlyout} />
                <Button
                  text="Create Entry"
                  type="submit"
                  color="navy"
                  size="xs" /> 
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default New;
