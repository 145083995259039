import { useMutation, useQuery } from '@apollo/client'; // need for signing
import { useEffect, useState } from 'react';
import {
  GetMroEmployeeDocument,
  GetMroWorkOrderQuery,
  CreateMroLogEntryDocument,
  GetWorkOrderLogEntriesDocument,
  GetMroWorkOrderIntervalsDocument,
  UpdateMroComponentDocument
} from 'graphql/generated';
import { Form, Formik } from 'formik';
import { FullFormikInput, FullFormikSelect, FullFormikTextarea } from 'components/Form/StandardForm';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { ChevronDownIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useSession } from 'contexts';

// ToDo: we consider breaking up this flyout out for edit and new
export default function NewLogEntryFlyout({
  categories,
  closeFlyout,
  workOrderId,
}: {
  categories?: GetMroWorkOrderQuery['mroWorkOrder']['itemCategories'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  workOrderId?: string;
}) {
  const [intervals, setIntervals] = useState([]);
  const [signIndex, setSignIndex] = useState(0);
  const [signOptions, setSignOptions] = useState([]);
  const [selectedSquawks, setSelectedSquawks] = useState(
    categories[0]?.mroWorkOrderItems
      .filter((item) => item.enableLogEntry)
      .map((item) => {
        return {
          id: item.id,
          description: item.logEntry,
          sortOrder: item.itemNumber,
          existingEntryItemId: '',
        };
      })
  );
  const { user } = useSession();
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: user?.mroEmployeeProfileId },
  });
  const { data: { mroWorkOrderIntervals } = {} } = useQuery( GetMroWorkOrderIntervalsDocument, { variables: { where: { mroWorkOrderId: { equals: workOrderId } } } });

  useEffect(() => {
    if (mroWorkOrderIntervals) {
      setIntervals(mroWorkOrderIntervals.map((interval) => ({ name: interval.name, value: interval.value })));
    }
  }, [mroWorkOrderIntervals]);
  const [createLogEntry] = useMutation(CreateMroLogEntryDocument, {
    refetchQueries: [{ query: GetWorkOrderLogEntriesDocument, variables: { mroWorkOrderId: workOrderId } }],
  });
  const [updateComponent] = useMutation(UpdateMroComponentDocument);
  useEffect(() => {
    if (mroEmployeeProfile) {
      setSignOptions([mroEmployeeProfile.firstName + ' ' + mroEmployeeProfile.lastName, mroEmployeeProfile.mroOrganization.name]); // 0 should be person, 1 should be mroOrganization
    }
  }, [mroEmployeeProfile]);

  const initialValues = {
    categoryIndex: 0,
    title: categories?.[0]?.title + ' Logs',
    certification: '',
    componentName: categories?.[0]?.mroComponent?.name,
    componentSerialNumber: categories?.[0].mroComponent?.serialNumber,
  };

  const handleSubmit = async (values: typeof initialValues, saveOnly?: boolean) => {
    const selectedCategory = categories?.[values.categoryIndex];
    await createLogEntry({
      variables: {
        input: {
          title: values.title,
          status: saveOnly ? 'INCOMPLETE' : 'SIGNED',
          sortOrder: selectedCategory.categoryNumber,
          mroWorkOrder: { connect: { id: workOrderId } },
          certificationText: values.certification,
          intervals: {
            ...(intervals.length > 0 && {
              create: intervals.map((interval) => {
                return {
                  name: interval.name,
                  intervalLogs: {
                    create: [
                      {
                        startValue: interval.value,
                      },
                    ],
                  },
                };
              }),
            }),
          },
          mroLogEntryItems: {
            create: selectedSquawks
              .map((squawk, index) => {
                return {
                  mroWorkOrderItem: { connect: { id: squawk.id } },
                  description: squawk.description,
                  sortOrder: index,
                };
              }),
          },
          mroComponent: {
            connectOrCreate: {
              where: { id: selectedCategory?.mroComponent?.id ?? '0' },
              create: { name: values.componentName, serialNumber: values.componentSerialNumber },
            },
          },
          itemCategory: { connect: { id: selectedCategory.id } },
          ...(!saveOnly && { signer: signIndex === 0 ? { connect: { id: mroEmployeeProfile.id } } : {}, signedAt: Date.now() }),
        },
      },
    });

    if (categories[values.categoryIndex]?.mroComponent?.id) {
      // update component if it already exists
      await updateComponent({
        variables: {
          input: { id: selectedCategory?.mroComponent?.id, name: values.componentName, serialNumber: values.componentSerialNumber },
        },
      });
    }
    closeFlyout();
  };

  const handleSquawkSeletion = function (value) {
    let tempArr = [...selectedSquawks];
    const i = tempArr.findIndex((e) => e.id === value.id);
    //remove from selectedSignoffs array
    if (i > -1) {
      tempArr.splice(i, 1);
    }
    //add back Sign Off
    //add to selectedSignoffs array
    else {
      tempArr.push({ ...value, description: value.logEntry });
    }
    setSelectedSquawks(tempArr);
  };

  const updateDescription = function (squawk, description) {
    let tempArr = [...selectedSquawks];
    const i = tempArr.findIndex((e) => e.id === squawk.id);
    tempArr[i].description = description;
    setSelectedSquawks(tempArr);
  };

  return (
    <>
      <div className="p-4">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col bg-white p-4 border border-slate-200 rounded">
                <FullFormikSelect
                  name="category"
                  label="Category"
                  onChange={(e) => {
                    const selectedCategory = categories[e.target.value];
                    if (values.title === '' || values.title.match(/\sLogs$/)) {
                      setFieldValue('title', selectedCategory.title + ' Logs');
                    }
                    setFieldValue('categoryIndex', e.target.value);
                    setFieldValue('componentName', selectedCategory?.mroComponent?.name);
                    setFieldValue('componentSerialNumber', selectedCategory?.mroComponent?.serialNumber);
                  }}>
                  {categories.map((category, index) => (
                    <option key={category.id} value={index}>
                      {category.title}
                    </option>
                  ))}
                </FullFormikSelect>
                <FullFormikInput name="title" label="Title" />
              </div>
              <div className="flex flex-col border bg-white rounded shadow border-slate-200 p-6 mt-3">
                <div className="flex justify-between items-center w-full relative">
                  <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Component</span>
                </div>
                <hr className="my-2 -m-6" />
                <FullFormikInput name="componentName" label="Name" />
                <FullFormikInput name="componentSerialNumber" label="Serial Number" />
              </div>
              <div className="flex flex-col border bg-white rounded shadow border-slate-200 p-6 mt-3">
                <div className="flex justify-between items-center w-full relative">
                  <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Intervals ({intervals.length})</span>
                </div>
                <hr className="my-2 -m-6" />
                {intervals.length > 0 && (
                  <div className="flex flex-wrap -mx-0.5 mt-1">
                    {intervals.map((interval, idx) => (
                      <div key={idx} className={`${intervals.length === 1 ? 'w-full' : 'w-1/2'} p-0.5`}>
                        <div className="flex items-center relative">
                          <input
                            className="w-3/5 h-6 px-2 bg-slate-50 text-sm font-medium rounded-l border border-r-0 border-slate-200 placeholder:text-slate-400"
                            placeholder="Interval Name"
                            value={interval.name}
                            onChange={(e) => {
                              intervals[idx].name = e.target.value;
                              setIntervals([...intervals]);
                            }}
                          />
                          <input
                            className="w-2/5 h-6 pr-6 text-sm border-slate-200 shadow-inner rounded-r"
                            value={interval.value}
                            type="number"
                            onChange={(e) => {
                              intervals[idx].value = Number(e.target.value);
                              setIntervals([...intervals]);
                            }}
                          />
                          <TrashIcon
                            className="w-6 p-1.5 text-rose-500 cursor-pointer hover:text-slate-500 transition absolute right-0"
                            onClick={() => {
                              setIntervals([...intervals.slice(0, idx), ...intervals.slice(idx + 1)]);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex justify-end my-3">
                  <div
                    onClick={() => {
                      setIntervals([...intervals, { name: '', value: 0 }]);
                    }}
                    className="flex gap-2 items-center text-white bg-brand p-1 hover:cursor-pointer rounded">
                    <PlusIcon className="h-3 w-3" />
                    <span className="text-xs font-semibold w-full ml-0.5">New Interval</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col bg-white p-4 border border-slate-200 rounded mt-3">
                <h2 className="font-bold text-sm my-2">Squawks</h2>
                {[...categories[values.categoryIndex].mroWorkOrderItems]
                  .sort((a, b) => a.itemNumber - b.itemNumber)
                  .map((item) => (
                    <div key={item.id}>
                      <div className="p-4 flex gap-3">
                        <input
                          type="checkbox"
                          className="mt-1 rounded h-3.5 w-3.5 -ml-3 mr-0.5 cursor-pointer"
                          id={item.id}
                          onChange={() => handleSquawkSeletion(item)}
                          checked={!!selectedSquawks.find((squawk) => item.id === squawk.id)}
                          name={item.id}
                        />
                        <span>
                          {categories[values.categoryIndex].categoryNumber}.{item.itemNumber} - {item.title}
                        </span>
                      </div>
                      <textarea
                        disabled={!selectedSquawks.find((squawk) => item.id === squawk.id)}
                        value={selectedSquawks.find((squawk) => item.id === squawk.id)?.description ?? ''}
                        onChange={(e) => updateDescription(item, e.target.value)}
                        className="rounded border p-2 text-sm bg-slate-50 border-slate-200 disabled:bg-white disabled:resize-none focus:bg-white placeholder:text-slate-400 w-full mt-2"
                      />
                    </div>
                  ))}
              </div>
              <div className="flex flex-col text-sm p-4 mt-4 bg-white border border-slate-200 rounded">
                <h1 className="font-bold">RTS Statement</h1>
                <FullFormikTextarea name="certification" placeholder="I Certify that..." />
              </div>
              <div className="flex flex-col text-sm bg-white p-4 mt-4 border border-slate-200 rounded">
                <div className="flex items-center justify-between pl-2 mt-1 gap-5">
                  <div className="flex items-stretch grow relative group">
                    <select
                      onChange={(e) => setSignIndex(Number(e.currentTarget.value))}
                      className="absolute w-full h-full z-10 opacity-0 cursor-pointer p-0"
                      value={signIndex}>
                      {signOptions.map((item, index) => {
                        return (
                          <option value={index} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <div className={`bg-brand text-sm flex font-semibold justify-center items-center rounded relative`}>
                      <span className="text-white flex w-full justify-center py-1 px-3 items-center select-none capitalize">
                        {signOptions[signIndex]}
                      </span>
                      <ChevronDownIcon className={`text-white border-l h-full w-6 py-[6.075px] p-1 rounded-r`} />
                    </div>
                  </div>

                  <button
                    onClick={() => handleSubmit(values)}
                    className="flex items-center font-semibold bg-brand-electric text-white border border-brand-electric cursor-pointer text-sm p-1 px-3 rounded hover:opacity-90 disabled:opacity-50 transition">
                    <PencilIcon className="h-2.5 w-2.5 mr-1" />
                    Sign
                  </button>
                  <button
                    onClick={() => handleSubmit(values, true)}
                    className="flex items-center font-semibold bg-white text-slate-500 border border-slate-500 cursor-pointer text-sm p-1 px-3 rounded hover:opacity-50 transition">
                    Save Entry
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}