import { endOfWeek, format, formatDistance, startOfWeek } from 'date-fns';
export const formatApiDate = (date) => {
  if (!date) return '';
  const dateObj = new Date(date);
  return format(dateObj, 'MM/dd/yyyy');
};

export const formatFieldDate = (date) => {
  if (!date) return '';
  const dateObj = new Date(date);
  return format(dateObj, 'yyyy-MM-dd');
};

export const formatDateChange = (date) => {
  if (!date) return '';

  var dateObj = new Date(date);
  var formattedDate = new Date(dateObj.getTime() + Math.abs(dateObj.getTimezoneOffset() * 60000));
  formattedDate.setHours(12, 0, 0, 0);
  return formattedDate;
};

export const formatApiDateTrunc = (date) => {
  if (!date) return '';

  const dateObj = new Date(date);
  dateObj.setHours(0, 0, 0, 0);
  return format(dateObj, 'MM/dd/yy');
};

export const formatApiTime = (date) => {
  if (!date) return '';

  const dateObj = new Date(date);
  return format(dateObj, 'hh:mm aa');
};

export const formatApiDateTime = (date) => {
  if (!date) return '';

  const dateObj = new Date(date);
  return format(dateObj, 'MM/dd/yyyy HH:mm');
};

export const formatApiDateTimeUTC = (date) => {
  if (!date) return '';

  const dateObj = new Date(date);
  
  // Get individual components and pad them with leading zeros if needed
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  const year = dateObj.getUTCFullYear();
  const hours = String(dateObj.getUTCHours()).padStart(2, '0');
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

  // Return the formatted date string
  return `${month}/${day}/${year} ${hours}:${minutes}`;
};

export const formatApiTimeDistance = (date) =>{
  if (!date) return '';

  const dateObj = new Date(date);
  var distance = formatDistance(dateObj, new Date(), { addSuffix: true } );
  if(distance === "less than a minute ago"){distance = "Now"};
  return distance;
}

export const formatUSD = (value: string | number) => {
  if (value === undefined || value === null) return '';

  return Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const formatToFixed = (value: string | number) => {
  if (value === undefined || value === null) return '';
  return Number(value).toFixed(2);
};

export const formatToFixedNumber = (value: string | number) => {
  if (value === undefined || value === null) return 0;
  return +Number(value).toFixed(2);
};

export const formatWithZeros = (num: number, places: number) => {
  return String(num).padStart(places, '0');
};

export const formatOrderNumber = (num: number, place?: string ) => {
  return `#${place ? place+'-' : ''}${num ? formatWithZeros(num, 4) : 'EST'}`;
}

export const formatCalendarMonth = (date) =>{
  if (!date) return '';
  const dateObj = new Date(date);
  return format(dateObj, 'LLLL yyyy');
}

export const formatCalendarMonthDay = (date) =>{
  if (!date) return '';
  const dateObj = new Date(date);
  return format(dateObj, 'MM/dd');
}

export const formatCalendarWeek = (date:Date) =>{
  if (!date) return '';
  return format(startOfWeek(date), 'M/dd')+" - "+format(endOfWeek(date), 'M/dd');
}

export const formatCalendarDay = (date) =>{
  if (!date) return '';
  const dateObj = new Date(date);
  return format(dateObj, 'LLLL dd, yyyy');
}

export const formatCalendarHour = (date) =>{
  if (!date) return '';
  const dateObj = new Date(date);
  return format(dateObj, 'ha');
}

export const formatCalendarTimeUTC = (date) => {
  if (!date) return '';

  const dateObj = new Date(date);

  let hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  
  // Determine AM/PM and adjust hours for 12-hour format
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 0 hours to 12 for AM/PM format

  // Format the time string
  return minutes === 0
    ? `${hours} ${ampm}`
    : `${hours}:${String(minutes).padStart(2, '0')} ${ampm}`;
};

export const formatCalendarExport= (date) => {
  if (!date) return '';

  const dateObj = new Date(date);
  return format(dateObj, 'yyyy-M-d-H-m');
}

export const formatPriceNumber = (num: number | string) => {
  return Number(Number(num).toFixed(2));
}

export const removeK = str => 
  str.length === 4 && (str.charAt(0) === 'K' || str.charAt(0) === 'I') ? str.slice(1) : str;

export const applyTimezone = (date: Date, timezone = 0, userTimezone = 0) => {
  
  if (!date) return new Date();
  const dateObj = new Date(date);

  dateObj.setHours(dateObj.getHours() + timezone + (timezone - userTimezone));
  return dateObj;
}