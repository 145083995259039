import { Reservation } from "graphql/generated";
import { formatCalendarMonthDay } from "utils/formatter";
import TimeSlots, { TimeSlotsType } from "../components/TimeSlots";
import { getDaysOfWeek } from "../utils/date";


function Week({ date, events, onEventSelected }:{date:Date, events?:Array<Object>, onEventSelected:(any)=>void}) {
    const [cols, intervals] = [["","Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], 48];
    var timeSlots:Array<Array<Date>> = [];
    
    var daysOfWeek = getDaysOfWeek(date);

    cols.forEach((value, index)=>{
        var col = [];
        var dayOfWeek = new Date((value===""?daysOfWeek[0]:daysOfWeek[index-1]).getTime() - (30*60000) ); // use sunday for blank col (-30 min to start at 12 am)
        for (let slot = 0; slot < intervals; slot++) {
            dayOfWeek = new Date(dayOfWeek.getTime() + 30*60000); //add 30 mins slots
            col.push(dayOfWeek);  
        }
        timeSlots[index] = col;
    });

    return (
        <div className="py-5 bg-white px-5 overflow-x-auto w-full items-center grid grid-col-7 rounded-b border-slate-300 shadow-blue">
            {/* days of week */}
            <div className="flex min-w-max text-md rounded-t border border-brand">
                {cols.map((value, index, arr)=>{
                    return(<div className={(index===arr.length-1?"border-transparent ":"")+"border-r border-brand w-full flex justify-center py-2 "+((index===0)?"flex-1":"min-w-[100px] flex-[1.7]")} key={index}>
                        {value+(value!==""?" "+formatCalendarMonthDay(daysOfWeek[index-1]):"")}
                    </div>);
                })}
            </div>
            {/* times of week */}
            <TimeSlots data={timeSlots} events={events} onClick={onEventSelected} type={TimeSlotsType.Week} />
        </div>
    );
  }
  
  export default Week;