import Card from "components/Card/Card";
import StripeButton from 'assets/images/stripe_connect.png';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FullFormikCheckBox, FullFormikInput } from "components/Form/StandardForm";
import { addMonths, format, startOfMonth } from "date-fns";
import { useMutation, useQuery } from "@apollo/client";
import { GetCraftBillingInfoDocument, GetCraftsWithUsersDocument, GetOrganizationBillingInfoDocument, GetOrganizationFlightBillingDocument, GetOrganizationWalletsDocument, GetTransactionsDocument, LogType, SortOrder, StripeDashboardDocument, UpdateCraftsDocument, UpdateOrganizationDocument } from "graphql/generated";
import { useSession } from "contexts";
import { useEffect, useMemo, useState } from "react";
import Button from "components/Button/Button";
import { formatApiDate, formatUSD } from "utils/formatter";
import { Table } from "components/Table/Table";
import UserIcon from "components/UserIcon/UserIcon";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import NewTransaction from "../Flyouts/NewTransaction";
import ViewTransaction from "../Flyouts/ViewTransaction";
import { useParams } from "react-router-dom";
const fieldCss =
  'rounded border border-slate-300 bg-slate-50 h-12 md:h-10 mb-4 px-2 text-brand focus:shadow-inner placeholder:text-slate-400 placeholder:text-sm';
const checkboxCss = 'rounded border-slate-200 p-3 -my-[1px] text-right strip-stylefont-semibold text-brand-dark bg-slate-50';

type CraftRate = {
    craftId: string;
    name: string;
    rate: string;
    loggingOption: LogType;
    originLoggingOption: LogType;
};

export default function Admin({ query, paymentId, setPaymentId } : { query: string, paymentId?: string, setPaymentId?: (id: string) => void}) {
    const { organizationId, user } = useSession();

    const clientId =  process.env.REACT_APP_STRIPE_CLIENT_ID;
    const redirectURI =  process.env.REACT_APP_STRIPE_REDIRECT_URI;
    const nextMonthFirstDay = startOfMonth(addMonths(new Date(), 1));
    const formattedDate = format(nextMonthFirstDay, 'MMM dd, yyyy');
    const params = useParams();

    const hourlyRateMethods = { [LogType.Logtach]: 'Tach', [LogType.Loghobbs]: 'Hobbs' };
    const [ limit, setLimit ] = useState(10);
    const [ skip, setSkip ] = useState(0);
    const { data: { crafts } = {} } = useQuery( GetCraftBillingInfoDocument, { variables: { organizationId } } );
    const { data: { organization } = {} } = useQuery( GetOrganizationBillingInfoDocument, { variables: { organizationId } } );
    const { data : { transactions, transactionCount } = {} } = useQuery( GetTransactionsDocument, { variables: { where: {
        wallet: { is: { organizationId: { equals: organizationId } } },
        ...( query && { OR:[
            { inputData: { path:[ 'description' ], string_contains: query  } },
            { wallet: { is: { userProfile: { is: { user: { is: { firstName: { contains: query }  } } } } } } },
            { wallet: { is: { userProfile: { is: { user: { is: { lastName: { contains: query }  } } } } } } },
            { value: { equals: Number(query) } },
          ] } )
    }, limit, skip, orderBy: { createdAt: SortOrder.Desc } } } );
    const { data : { wallets } = { } } = useQuery( GetOrganizationWalletsDocument, { variables: { organizationId } });
    const { data : { stripeDashboard } = {} } = useQuery( StripeDashboardDocument, { variables: { id: organizationId } });
    const [ updateOrganizationInfo ] = useMutation( UpdateOrganizationDocument, { refetchQueries: [ 
        { query: GetOrganizationFlightBillingDocument, variables: { id: organizationId } },
     ] });
    const [ updateCrafts ] = useMutation(UpdateCraftsDocument, { refetchQueries: [ 
        { query: GetCraftsWithUsersDocument, variables: { organizationId } }
    ]});


    const [ craftRates, setCraftRates ] = useState([] as CraftRate[]);
    const [ initialValues, setInitialValues ] = useState({ enableMonthlyContribution: false, monthlyContribution: 0, flightBilling: false });

    const { toastProps, showToast } = useToast();
    const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                className: 'text-left w-3/12',
                accessor: 'createdAt',
                Cell: ({ value }: any) => {
                return (
                    <div className="w-full text-left">
                    <span>{formatApiDate(value)}</span>
                    </div>
                );
                },
            },
            {
                Header: 'Pilot',
                accessor: 'wallet.userProfile.user',
                className: 'text-center w-3/12',
                Cell: ({ row, value }: any) => {
                    return (
                    <div className="w-full text-left">
                        <span>{value?.firstName} {value?.lastName}</span>
                    </div>
                    );
                }
            },
            {
                Header: 'Description',
                accessor: 'inputData.description',
                className: 'w-3/12',
            },
            {
                Header: 'Qty',
                accessor: (row: any) => row.inputData,
                className: 'text-center w-2/12',
                Cell: ({ row, value }: any) => {
                return (
                    <div className="pl-2">
                        <span>{Number(value?.quantity).toFixed(2) ?? 'Unknown'}</span>
                    </div>
                );
                }
            },
            {
                Header: 'Rate',
                accessor: (row: any) => row.inputData,
                className: 'text-center w-2/12',
                Cell: ({ row, value }: any) => {
                return (
                    <div className="pl-3">
                        <span>{value?.rate ?? 'Unknown'}</span>
                    </div>
                );
                }
            },
            {
                Header: 'Amount',
                accessor: (row: any) => ({ value: row?.value, isDeposit: row?.isDeposit }),
                className: 'text-center w-2/12',
                Cell: ({ row, value }: any) => {
                    return (
                        <div className="w-full text-left">
                            <span>{value.isDeposit && '-' } {formatUSD(value.value) ?? 'Unknown'}</span>
                        </div>
                    );
                }
            },
        ],
        []
    );
    useEffect(() => {
        if (crafts) {
            setCraftRates(crafts.map((craft) => 
                ({ craftId: craft?.id,
                    rate: craft?.hourlyRate?.toString(), 
                    originLoggingOption: craft.loggingOption,
                    loggingOption: craft?.hourlyRateMethod as any ?? craft.loggingOption, 
                    name: craft.name + ' - ' + craft.tailNumber })
            ));
        }
    }, [crafts]);
    useEffect(() => {
        if (organization) { 
            setInitialValues({ enableMonthlyContribution: organization.monthlyContributionEnabled, monthlyContribution: organization.monthlyContribution, flightBilling: organization.flightBillingEnabled });
        }
    }, [organization]);
    useEffect(() => {
        if (paymentId) {
            updateFlyout( { title: 'View Transaction', content: <ViewTransaction closeFlyout={closeFlyout} id={paymentId} /> } );
            setPaymentId('');
        }
    }, [paymentId]);
    return (
    <>
        <Flyout {...flyoutProps} />
        <Formik 
        enableReinitialize 
        initialValues={initialValues}
        onSubmit={async (values: typeof initialValues) =>{
            try{
                await updateOrganizationInfo({ variables: { input: { id: organizationId, monthlyContribution: values.monthlyContribution, monthlyContributionEnabled: values.enableMonthlyContribution, flightBillingEnabled: values.flightBilling } } });
                await updateCrafts({ variables: { input: craftRates.map( (rate) => ({id: rate.craftId , hourlyRateMethod: rate.loggingOption, hourlyRate: Number(rate.rate) }) ) } });
                showToast({ type: ToastLength.Normal, title: 'Account Settings Updated' });
            }catch(e){
                console.log(e);
            }
        }}>
            {({ isSubmitting, values }) => (
                <Form>
                    <Card title="Account Payment Settings" titleSize="sm" subtitle="Use this area to manage all Payments in your account." className="-mt-[4.5px] relative" >
                        <h2 className="mb-5 font-semibold"></h2>
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-row justify-between items-center flex-wrap">
                                <label className="font-medium text-brand">Payments</label>
                                { !stripeDashboard || stripeDashboard === 'stripe not setup for this organization' ? 
                                    <a href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectURI}&client_id=${clientId}&suggested_capabilities[]=card_payments`}>
                                        <img src={StripeButton} className="h-8 w-35" />
                                    </a>
                                    : 
                                    <a href={stripeDashboard}><Button text="Manage Stripe Account" size="xs" color="navy" /></a>
                                }
                            </div>
                            <hr className="border-gray-300" />
                            <div className="flex flex-col px-1 gap-10">
                                <div className="flex gap-6 items-center">
                                    <label className="font-medium text-brand">Enable Monthly Contribution</label>
                                    <div className="flex flex-col">
                                        <Field
                                            className={checkboxCss}
                                            id="enableMonthlyContribution"
                                            name="enableMonthlyContribution"
                                            type="checkbox"
                                            />
                                        <ErrorMessage name="enableMonthlyContribution" />
                                    </div>
                                </div>
                                <div className="flex lg:flex-row flex-col gap-5 items-center">
                                    <label className="font-medium text-brand mb-3">Monthly Fixed Contribution</label>
                                    <div className="flex flex-col">
                                        <div className="flex flex-wrap justify-center">
                                            <div className="text-brand text-sm p-2 border bg-slate-200 border-r-0 border-slate-300 h-12 md:h-10">$</div>
                                            <Field placeholder="0.00" className={fieldCss+' border-x-0 w-24'} name="monthlyContribution" ></Field>
                                            <div className="text-brand text-sm p-2 border bg-slate-200 border-l-0 border-slate-300 h-12 md:h-10">per Month</div>
                                        </div>
                                        <ErrorMessage name="monthlyContribution" component="div" className="text-red-500 text-xs italic" />
                                        <span className="text-xs">Will be applied at the first of every calendar month. The next debit will be on {formattedDate}</span>
                                    </div>
                                </div>
                            </div>
                            <hr className="border-gray-300" />
                            <div className="flex gap-6 px-1 mb-3 items-center">
                                <label className="font-medium text-brand mr-10">Flight Billing</label>
                                <div className="flex flex-col">
                                    <Field
                                        className={checkboxCss}
                                        id="flightBilling"
                                        name="flightBilling"
                                        type="checkbox"
                                        />
                                    <ErrorMessage name="flightBilling" />
                                </div>                               
                            </div>
                            { craftRates?.map((craft, index) => (
                                <div key={craft?.craftId} className="flex flex-col gap-5 px-1">
                                    <h1 className="font-bold -mb-3">{craft?.name}</h1>
                                    <hr className="border-gray-300 max-w-lg" />
                                    <div className="flex lg:flex-row flex-col gap-5 items-center">
                                        <label className="font-medium text-brand mb-3">Hourly Rate</label>
                                        <div className="flex flex-col">
                                            <div className="flex flex-wrap">
                                                <div className="text-brand text-sm p-2 border bg-slate-200 border-r-0 border-slate-300 h-12 md:h-10">$</div>
                                                <input placeholder="0.00" className={fieldCss+' border-x-0 w-24'} name="craftValue" value={craft?.rate ?? 0} 
                                                    onChange={(e)=> { 
                                                        const newCraftRates = [...craftRates];
                                                        newCraftRates[index].rate = e.target.value;
                                                        setCraftRates(newCraftRates);
                                                    }}></input>
                                                <div className="text-brand text-sm p-2 border bg-slate-200 border-l-0 border-slate-300 h-12 md:h-10">per Hour</div>
                                            </div>
                                        </div>
                                    </div>  
                                    <div className="flex gap-5 items-center">
                                        <label className="font-medium text-brand mb-3">Hourly Rate Method</label>
                                        {craft?.originLoggingOption !== LogType.Logboth && <label className="font-medium text-brand mb-3">{hourlyRateMethods[craft?.loggingOption]}</label>}
                                        {craft?.originLoggingOption === LogType.Logboth && <select value={craft?.loggingOption} onChange={(e) => {
                                            const newCraftRates = [...craftRates];
                                            newCraftRates[index].loggingOption = e.target.value as LogType;
                                            setCraftRates(newCraftRates);
                                        }} className={fieldCss+' w-24'} name="loggingOption">
                                            <option value={LogType.Logtach}>Tach</option>
                                            <option value={LogType.Loghobbs}>Hobbs</option>
                                        </select>}
                                    </div>  
                                </div> 
                            ))} 
                            <Toast {...toastProps}  />
                            <Button text="Save" color="navy" type='submit' />
                        </div>
                    </Card>
                    <div className="flex flex-col shrink-0 xl:flex-row xl:gap-5">
                        <Card title="Deposits & Transactions" titleSize="sm" subtitle="Manage all Owner Deposits and Transactions." className="-mt-[4.5px] xl:w-2/3" >
                            <div className="flex gap-10 justify-end lg:-mt-12 mb-3">
                                <a download={true} href={process.env.REACT_APP_API_URL+"/transactions/"+organizationId}><Button text="Export" size='xs' color="navy" /></a>
                                <Button text="New Transaction" size='xs' color="navy" onClick={() => updateFlyout( { title: 'New Transactions', content: <NewTransaction closeFlyout={closeFlyout} /> } )} />
                            </div> 
                            <Table
                                columns={columns}
                                count={transactionCount}
                                limit={limit}
                                skip={skip}
                                data={transactions ?? []}
                                onRowClick={(row) => setPaymentId(row?.id)}
                            /> 
                        </Card>
                        <Card title="Account Summary" titleSize="sm" subtitle="Below is a snapshot of the current account summary." className="-mt-[4.5px] xl:w-1/3" >
                            <div className="flex flex-col items-center justify-center gap-2 px-1 py-2">
                                <h1 className="font-bold text-lg">Owed by Owners</h1>
                                <h1 className={`font-semibold ${Number(organization?.totalOwed) <= 0 ? 'text-emerald-500' : 'text-red-500' }`}>{formatUSD(organization?.totalOwed)}</h1>
                            </div>
                            <hr className="border-gray-300 my-2" />
                            { wallets?.map((wallet, index) => (
                                <div key={index} className="flex justify-between gap-5 px-1 cursor-pointer hover:bg-slate-200 pb-2 last:pb-0">
                                    <div className="flex gap-5">
                                        <UserIcon iconSize="xl" iconText={wallet?.userProfile?.user?.firstName + ' ' + wallet?.userProfile?.user?.lastName} iconId={null} iconColor="cust" className="" />
                                        <div className="flex flex-col gap-2">
                                            <h1 className="font-bold -mb-3">{wallet?.userProfile?.user?.firstName} {wallet?.userProfile?.user?.lastName}</h1>
                                            <h2 className="font-semibold text-brand">{wallet?.transactions.length} { wallet?.transactions?.length !== 1 ? 'Transactions' : 'Transaction'}</h2>
                                        </div>
                                    </div>                                
                                    <div className={`${Number(organization?.totalOwed) <= 0 ? 'text-emerald-500' : 'text-red-500' }`}>{formatUSD(wallet?.balanceCache)}</div>
                                </div>
                            ))}
                        </Card>
                        
                    </div>
                </Form> 
            )}
        </ Formik>
    </>);
}