import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { SignUpContext } from '..';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { LookupTailDocument, NewUserSignupDocument, OnboardOrgDocument, Role, SignupForServiceDocument } from 'graphql/generated';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import * as Yup from 'yup';
import { FullFormikInput } from 'components/Form/StandardForm';
import { useNavigate } from 'react-router-dom';

const TailNumberSchema = Yup.object().shape({
    tailNumber: Yup.string().required('Required'),
});
  

const AddTailNumber = (props) => {
    const { resetState, formStep, changeStep, prevStep, setTailInfo, setTailNumber, tailNumber } = useContext(SignUpContext);
    const { toastProps, showToast } = useToast();
    const [lookupTail] = useLazyQuery(LookupTailDocument, { fetchPolicy: 'network-only' });
    const [ info, setInfo ] = useState({ tailNumber: '' });
    useEffect(() => {
        setInfo({ tailNumber });
    }, [tailNumber]);
    return (
        <div className='relative'>
            <Toast {...toastProps} />
            <Formik
                validationSchema={TailNumberSchema}
                initialValues={info}
                enableReinitialize
                onSubmit={async (values: typeof info) => {
                try {
                    const formatedTail = values?.tailNumber.startsWith('N') ? values?.tailNumber.substring(1) : values?.tailNumber;
                    const tailInfo = (await lookupTail({ variables: { tailNumber: formatedTail } }))?.data?.registrations?.[0];
                    setTailNumber(values?.tailNumber);
                    setTailInfo(tailInfo);
                    changeStep(formStep, 'confirmRegistration');
                } catch (error) {
                    const { message } = error as ApolloError;
                    showToast({ title:message, type: ToastLength.Long });
                
                }}}>
                {({ values, isSubmitting, errors }) => (
                <Form className="w-96">
                    <div className="flex flex-col mb-4">
                        <span className="text-3xl md:text-2xl font-bold text-brand">Add Your First Aircraft</span>
                    </div>
                    <div className="flex flex-col mb-4">
                        <span className="font-semibold text-gray-400">Enter your tail number below and we’ll do a bit of magic to speed things along.</span>
                    </div>
                    <div className="flex flex-col">
                        <FullFormikInput name="tailNumber" label="Tail Number" autoComplete="off" />
                    </div>
                    <div className="flex flex-col gap-5 justify-center items-center mt-5">
                        <div className='flex gap-3'>
                            <Button text="Continue" color="" size="sm" disabled={isSubmitting || Object.keys(errors).length > 0} type="submit" />
                        </div>
                        <div className='flex gap-3'>
                            { window.location.pathname.includes('/sign-up') ? 
                                <Button text="Sign Out" color="pale" size="sm" onClick={() => resetState()} />
                                : <Button text="Back" color="pale" size="sm" onClick={() => window.history.go(-2)} />}
                        </div>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddTailNumber;
