import { useState, useMemo } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import CustomerCraft from './Partials/CustomerCraft';
import { Table } from 'components/Table/Table';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import Invoice from './Partials/Invoice';
import { useQuery } from '@apollo/client';
import { GetMroCustomerDocument, GetMroInvoicesDocument } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { PlusIcon } from 'assets/icons';
import NewMember from './Partials/NewMember';
import { formatApiDate, formatUSD } from 'utils/formatter';
import Button from 'components/Button/Button';

const Customer = function () {
  const [currentTab, setCurrentTab] = useState('overview');
  const { customerId } = useParams<{ customerId: string }>();
  const { data: { mroCustomer } = {} } = useQuery(GetMroCustomerDocument, {
    variables: {
      mroCustomerId: customerId,
    },
  }); 
  const { data: { mroInvoices } = {} } = useQuery(GetMroInvoicesDocument, {
    variables: {
      where: {
        mroWorkOrder: {
          is: {
            mroCustomerId: { in: [customerId] },
          },
        },
      },
    },
  });
  const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
  const columns = useMemo(
    () => [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNumber',
        className: 'w-full',
        Cell: ({ value, row }: any) => {
          return (
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded text-sm w-full text-center">
              {value}
            </span>
          );
        },
      },
      {
        Header: 'Issued',
        accessor: 'invoiceDate',
        className: 'w-full',
        Cell: ({ value }) => formatApiDate(value),
      },
      {
        Header: 'Due',
        accessor: 'dueDate',
        className: 'w-full',
        Cell: ({ value }) => formatApiDate(value),
      },
      {
        Header: 'Item #',
        accessor: 'items.length',
        className: 'w-full justify-end',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        className: 'w-full justify-end',
        Cell: ({ value }) => formatUSD(value),
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-full min-w-[8rem]',
        Cell: ({ value, row }: any) => {
          return (
            <div
              className={`${
                value === 'Paid' ? 'bg-brand-pale text-brand-electric' : 'bg-red-100 text-red-500'
              } text-xs uppercase rounded py-1 px-2 text-center w-full`}>
              <span className="font-bold">{value}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Flyout {...flyoutProps} />
      <div className="bg-white border rounded border-slate-300">
        <div className="flex rounded-t items-end justify-between p-6">
          <div className="flex items-center">
            <div className="bg-brand-pale rounded h-12 w-12 flex items-center justify-center">
              <span className="text-brand-electric uppercase font-bold text-xl">{mroCustomer?.name[0]}</span>
            </div>
            <div className="ml-2 flex flex-col">
              <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">{mroCustomer?.name}</h1>
              <div className="-mt-0.5 text-sm text-slate-500">
                <span className="text-brand-electric font-medium mr-2 pr-2 border-r border-slate-300">{mroCustomer?.email}</span>
                <span className="mr-2 pr-2 border-r border-slate-300">{mroCustomer?.phoneNumbers?.primary}</span>
                <span>{mroCustomer?.mroContacts?.length ?? '0'} Members</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="flex-col text-center bg-brand-offwhite border-brand-pale border text-brand-electric rounded pt-0.5 px-1">
              <h3 className="font-bold text-brand-electric uppercase text-sm -mb-1">{mroCustomer?.mroCrafts?.length}</h3>
              <span className="text-xs text-brand uppercase font-bold">Crafts</span>
            </div>
            <div className="flex-col mr-3 text-center bg-brand-offwhite border-brand-pale border text-brand-electric rounded pt-0.5 px-1">
              <h3 className="font-bold text-brand-electric uppercase text-sm -mb-1">{mroCustomer?.mroWorkOrders?.length}</h3>
              <span className="text-xs text-brand uppercase font-bold">Work Orders</span>
            </div>
            <EllipsisVerticalIcon className="h-5 w-5 text-slate-500" />
          </div>
        </div>
      </div>
      <div className="flex items-start pt-2 -mb-[1px]">
        <button
          className={`${
            currentTab !== 'overview' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
          } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300`}
          disabled={currentTab === 'overview'}
          onClick={() => setCurrentTab('overview')}>
          Overview
        </button>
        <button
          className={`${
            currentTab !== 'billing' ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
          } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300`}
          disabled={currentTab === 'billing'}
          onClick={() => setCurrentTab('billing')}>
          Billing
        </button>
      </div>
      {/* OVERVIEW TAB */}
      <div hidden={currentTab !== 'overview'} className="bg-slate-100 shadow-inner border rounded-b rounded-r border-slate-300">
        <div className="flex gap-2">
          <div className="flex flex-col w-2/3 p-4 border-r border-slate-300 bg-white rounded-bl">
            <div className="flex flex-col">
              {/* <h2 className="text-brand-dark font-bold border-b border-slate-200 p-2 px-4">Crafts</h2> */}
              {mroCustomer?.mroCrafts?.map((item, index) => {
                return <CustomerCraft mroCraft={item} craftsLength={mroCustomer?.mroCrafts.length} key={index} />;
              })}
            </div>
          </div>
          <div className="flex flex-col w-1/3 py-4 pr-4 pl-2 rounded-r">
            <div className="shadow-blue border border-slate-200">
              <div className="flex justify-between items-center text-brand-dark font-bold bg-white rounded-t p-4">
                <h2 className="">Members</h2>
                <Button
                  color="white"
                  size="xs"
                  text="Add New"
                  onClick={() =>
                    updateFlyout({
                      title: 'Add Member',
                      content: (
                        <div className="p-4">
                          <div className="flex flex-col w-full border border-slate-200 rounded p-10 bg-white shadow-blue">
                            <NewMember mroCustomerId={customerId} closeFlyout={closeFlyout} />
                          </div>
                        </div>
                      ),
                    })
                  }
                />
              </div>
              <div className="flex flex-col p-4 bg-white border-t border-dashed border-brand-pale rounded-b">
                {mroCustomer?.mroContacts?.map((item, index) => {
                  return (
                    <div
                      className="flex group items-center border-brand-pale rounded p-2 cursor-pointer transition-all hover:bg-brand-pale/40"
                      key={index}>
                      <div className="bg-brand-pale rounded h-10 w-10 flex items-center justify-center transition-all">
                        <span className="text-brand-electric uppercase font-bold text-lg">{item.firstName[0]}</span>
                      </div>
                      <div className="flex flex-col ml-2">
                        <h1 className="text-brand-dark text-left font-bold">{item.firstName + ' ' + item.lastName}</h1>
                        <div className="-mt-1 text-sm text-slate-500">
                          <span className="text-brand-electric font-medium mr-2 pr-2 border-r border-slate-300">{item.email}</span>
                          <span>{item.phoneNumbers?.primary}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {mroCustomer?.mroContacts.length === 0 && (
                  <button
                    onClick={() =>
                      updateFlyout({
                        title: 'Add Member',
                        content: (
                          <div className="p-4">
                            <div className="flex flex-col w-full border border-slate-200 rounded p-10 bg-white shadow-blue">
                              <NewMember mroCustomerId={customerId} closeFlyout={closeFlyout} />
                            </div>
                          </div>
                        ),
                      })
                    }
                    className="flex group items-center bg-brand-offwhite border border-brand-pale rounded p-2 mt-2 cursor-pointer transition-all hover:bg-white">
                    <div className="bg-brand-pale rounded h-10 w-10 flex items-center justify-center">
                      <PlusIcon className="h-3 w-3 text-brand-electric" />
                    </div>
                    <div className="flex flex-col ml-2">
                      <h1 className="text-brand-dark text-left font-bold">Add A Member</h1>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BILLING TAB */}
      <div hidden={currentTab !== 'billing'} className="bg-white border rounded-b rounded-r border-slate-300">
        <div className="flex gap-2">
          <div className="w-full p-4">
            <Table
              className="w-full bg-white rounded"
              columns={columns}
              data={mroInvoices}
              onRowClick={(row) => {
                updateFlyout({
                  title: 'Customer Invoice #' + row.id,
                  content: <Invoice invoice={row} />,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
