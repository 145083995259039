import { useMutation, useQuery } from '@apollo/client';
import {
  Severity,
  Status,
  GetCraftDocument,
  GetSquawksDocument,
  GetComponentsDocument,
  UpdateSquawkDocument,
  GetSquawkDocument,
  SquawkUpdateInput,
  DeleteSquawkDocument,
  GetCraftsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import JascSelect, { JascSystem } from 'components/JascCategories/JascSelect';
import DatePicker from 'components/DatePicker/DatePicker';
import Card from 'components/Card/Card';
import { FullFormikInput } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import FilePicker from 'components/FilePicker/FilePicker';
import Modal from 'components/Modal/Modal';

export const StyledSelect = (props) => (
  <select
    className="rounded border px-2 w-2/3 block pl-3 pr-10 py-2 text-sm border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    {...props}></select>
);

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

type SquawkFiles = File | { signedUrl?: string; name?:string; id?: string };

const Edit = function ({ id, closeFlyout }: { id: string; closeFlyout: (deleted?:boolean) => void} ) {
  const { craftId, organizationId } = useSession();
  const [updateSquawk] = useMutation(UpdateSquawkDocument, {
    refetchQueries: [
      { query: GetSquawksDocument, variables: { craftId: craftId } },
      { query: GetCraftDocument, variables: { craftId } },
      { query: GetCraftsDocument, variables: { organizationId } },
      { query: GetComponentsDocument, variables: { craftId } },
    ],
  });
  const [deleteSquawk] = useMutation(DeleteSquawkDocument, {
    variables: { id },
    refetchQueries: [
      { query: GetSquawksDocument, variables: { craftId: craftId } },
      { query: GetCraftDocument, variables: { craftId } },
      { query: GetCraftsDocument, variables: { organizationId } },
      { query: GetComponentsDocument, variables: { craftId } },
    ],
    onCompleted: () => closeFlyout(true),
  });
  const { data: { squawk } = {} } = useQuery(GetSquawkDocument, { variables: { id } });
  const { data: { craft } = { craft: undefined } } = useQuery(GetCraftDocument, { variables: { craftId } });
  const [ modal, setModal ] = useState(false);
  const [files, setFiles] = useState<SquawkFiles[]>([]);

  const [initialValues, setInitialValues] = useState({
    status: 'IN_PROGRESS',
    severity: 'WARNING',
    dateOpened: new Date(),
    title: '',
    description: '',
    component: '',
    systemsAffected: [],
  });

  useEffect(() => {
    if (squawk) {
      setFiles( squawk?.attachments?.map((attach) => ({  id: attach.id, signedUrl: attach.signedUrl, name: attach.name })) );
      setInitialValues({
        status: squawk.status as Status,
        severity: squawk.severity as Severity,
        dateOpened: squawk.openedAt,
        title: squawk.title,
        description: squawk.description,
        component: squawk?.component?.id,
        systemsAffected: squawk.systemsAffected?.map((system) => system.id+'/'+system.name+'/'+system.code ),
      });
    }
  }, []);
  
  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const squawkInput: SquawkUpdateInput = {
      id,
      status: values.status as Status,
      severity: values.severity as Severity,
      openedAt: values.dateOpened,
      title: values.title,
      description: values.description,
      craft: { connect: { id: craftId } },
      component: { connect: { id: values.component } },
      systemsAffected: { 
        disconnect: squawk?.systemsAffected.filter((system) => !values.systemsAffected.find((sys) => sys.split('/')[0] === system.id ) ).map((system) => ({ id: system.id })), 
        connect: values.systemsAffected.map((system) => ({ id: system.split('/')[0] })) 
      },
      attachments: {
        ...( squawk?.attachments.filter((attach) => attach.id ).length > 0 &&
            { disconnect: squawk?.attachments.map((attach) => ({ id: attach?.id }) ) }
        ),
        ...( files.filter((attach) => (attach as any)?.id ).length > 0 &&
          { connect: files.map((attach) => ({ id: (attach as any)?.id }) ) }
        )
      },
    };
    await updateSquawk({ variables: { input: squawkInput } });
    if (files.filter((file) => (file as any)?.size)) {
      const formData = new FormData();
      files.filter((file) => (file as any)?.size).forEach((file) => {
        formData.append('files', file as any);
      });
      formData.append('squawkId', id);
      const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
    }
    closeFlyout();
  };

  return (
    <>
      { modal && <Modal message={'You are about to delete the '+squawk?.title+' squawk.'} accept={deleteSquawk} cancel={setModal} /> }
      <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Card className='m-4'>
              <dl>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
                </div>
                <div className="p-4 flex items-center justify-center">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Component</dt>
                  <Field as={StyledSelect} name="component">
                    {craft?.components.map((component) => (
                      <option key={component.id} value={component.id}>
                        {component.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="p-4 flex items-center justify-center">
                  <div className='w-1/3 mb-[-15px]'>
                    <dt className="text-sm font-medium text-slate-500">Systems Affected</dt>
                    <a href='https://sdrs.faa.gov/documents/JASC_Code.pdf' target='_' className="text-xs font-medium text-slate-500 underline">(JASC Table And Definitions)</a>
                  </div>
                  <Field
                    as={JascSelect}
                    name="systemsAffected"
                    className="w-2/3"
                    onChange={(value) => {
                      setFieldValue('systemsAffected', value);
                    }}>
                    <option>1230 - Hydraulic Fuel servicing</option>
                  </Field>
                </div>
                <div className="p-4 flex items-center justify-center">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Date Opened</dt>
                  <Field as={DatePicker} name="dateOpened"></Field>
                </div>
                <div className="p-4 flex flex-col">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Attachments</dt>
                  <FilePicker files={(files as any)} setFiles={setFiles} />
                </div>
                <div className="p-4">
                  <dt className="text-sm font-medium text-slate-500 mb-2">Description</dt>
                  <Field
                    as="textarea"
                    rows="4"
                    className="w-full border border-slate-300 rounded text-sm text-slate-500 p-2"
                    name="description"></Field>
                </div>
              </dl>
            </Card>
            <div className="px-4 mb-10 flex items-center justify-end gap-10">
              <Button
                type="button"
                size='xs'
                color='red'
                onClick={() => setModal(true)}
                disabled={isSubmitting}
                text='Delete' />
              <Button
                type="submit"
                size='xs'
                disabled={isSubmitting}
                text='Update Squawk' />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Edit;
