import Button from "components/Button/Button";
import Tabs from "components/Tabs/Tabs";
import { Craft } from "graphql/generated";
import { useMemo, useState } from "react";
import { formatCalendarDay, formatCalendarMonth, formatCalendarWeek } from "utils/formatter";
import Day from "./views/Day";
import Month from "./views/Month";
import Week from "./views/Week";
import SegmentControl from "components/SegmentControl/SegmentControl";
import { useLayoutEffect } from "react";

function Calendar(props) {
    const [activeTab, setActiveTab] = useState("Month");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const tabs = ["Month", "Week", "Day"];

    const getTimeTitle = (mode, time):string=>{
        if(mode=="Month"){return formatCalendarMonth(time)}
        else if(mode=="Week"){return formatCalendarWeek(time)}
        else if(mode=="Day"){return formatCalendarDay(time)};
        return "";
    }
    const paginateDate = (mode, amount)=>{
        if(mode==="Month"){setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth()+amount, selectedDate.getDate()))}
        else if(mode=="Week"){setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()+(amount*7) ))}
        else if(mode=="Day"){setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()+amount ))};
    }
    const isToday = (mode, date:Date):boolean=>{
        const today = new Date();
        if(mode==="Month"){return selectedDate.getFullYear()===today.getFullYear() && selectedDate.getMonth()===today.getMonth() }
        else if(mode==="Week"){
            var sunday = new Date(date.getFullYear(), date.getMonth(), (date.getDate()-date.getDay()));
            var saturday = new Date(date.getFullYear(), date.getMonth(), (date.getDate()-(date.getDay()-6)));
            return (today > sunday && today < saturday);
        }else if(mode==="Day"){return selectedDate.getFullYear()===today.getFullYear() && selectedDate.getMonth()===today.getMonth() && selectedDate.getDate()===today.getDate() };
        return false;
    }

    const MonthView = useMemo(() => Month({ ...props, date:selectedDate }), [props, selectedDate]);
    const WeekView = useMemo(() => Week({ ...props, date:selectedDate }), [props, selectedDate]);
    const DayView = useMemo(() => Day({ ...props, date:selectedDate }), [props, selectedDate]);

    return (
      <div className="rounded-md bg-offwhite">
        {/* Header/Controls */}
        <div className="flex justify-between flex-wrap items-center bg-white rounded-t border-slate-300 w-full px-5 py-3 shadow-blue">
            {/* left buttons */}
            <div className="flex">
                <Button text={"<"} 
                color="navy"
                size="xs"
                onClick={()=>paginateDate(activeTab, -1)}
                className="p-2 mr-2 rounded bg-brand text-white hover:bg-brand/50" />
                <Button text={">"} 
                color="navy"
                size="xs"
                onClick={()=>paginateDate(activeTab, 1)}
                className="p-2 mr-2 rounded bg-brand text-white hover:bg-brand/50" />
                <Button text={"Today"} 
                color="navy"
                size="xs"
                onClick={()=>setSelectedDate(new Date())}
                className={(isToday(activeTab, selectedDate)?"bg-brand/50":"bg-brand") + " p-2 rounded text-white hover:bg-brand/50"} />
            </div>
            {/* title */}
            <div className="flex">
                <span className="text-2xl font-semibold">{getTimeTitle(activeTab, selectedDate)}</span>
            </div>
            {/* tab bar */}
            <div className="flex">
                <SegmentControl onChange={setActiveTab} activeItem={activeTab} items={tabs}  />
            </div>
        </div>
        {/* View */}
        <div>
            { activeTab=="Month" && MonthView }
            { activeTab=="Week" && WeekView }
            { activeTab=="Day" && DayView }
        </div>
      </div>
    );
  }
  
  export default Calendar;