import { useMutation } from "@apollo/client";
import { UpdateStripeConnectDocument } from "graphql/generated";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const StripeCallback = () => {
    const urlParams = new URLSearchParams(window.location?.search);
    const code = urlParams?.get('code');    
    const navigate = useNavigate();
    const [ updateStripeConnect ] = useMutation(UpdateStripeConnectDocument);
    console.log('run1123//',code);
    useEffect(() => {
        if(code) {
            updateStripeConnect({
                variables: {
                    id: code
                }
            }).then(() => {
                navigate('/payments?requestsTab=Admin');
            });
        }
    }, [code]);
    return (
        <div className="p-10">
            <h1>Redirecting Back to App</h1>
        </div>
    );
}

export default StripeCallback;
