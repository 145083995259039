import { Link, useLocation } from 'react-router-dom';
export default function MobileThumbBar({ mobileNav }: { mobileNav: Array<{ icon; name; active; href; alt?; }> }) {
  const href = useLocation().pathname;
  return (
    <>
      {/* Bottom Gradient */}
      <div className="fixed z-40 bottom-20 left-0 w-screen h-10 bg-gradient-to-t from-[#FAFAFD] to-transparent pointer-events-none md:hidden"></div>
      {/* Bar */}
      <div className="fixed z-50 bottom-0 left-0 w-screen h-20 bg-white border-t md:hidden flex">
        {mobileNav.map((item) => {
          item.active = href.includes(item.href) || (item.alt && href.includes(item.alt));
          return (
            <Link
              to={item.href}
              className={`${
                item.active ? '' : 'opacity-50'
              } text-brand-dark hover:opacity-100 flex flex-col justify-center items-center flex-1 text-xs font-semibold`}
              key={item.name}>
              <item.icon className="w-5 h-5 mb-2" aria-hidden="true" />
              {item.name}
            </Link>
          );
        })}
      </div>
    </>
  );
}
