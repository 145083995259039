import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteCategoryDocument, GetMroWorkOrderDocument, GetMroWorkOrderQuery, UpdateMroWorkOrderItemDocument } from 'graphql/generated';
import { formatApiDate, formatToFixed } from 'utils/formatter';
import { PlusIcon, ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import UserIcon from 'components/UserIcon/UserIcon';
import StatusButton from 'components/StatusButton/StatusButton';
import NewCategoryFlyout from '../../WorkOrderItems/NewCategoryFlyout';
import Modal from 'components/Modal/Modal';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import Category from '../../Components/Category';
import UserCard from 'components/UserCard/UserCard';
import { useNavigate } from 'react-router-dom';
import Tabs from 'components/Tabs/Tabs';

export default function Overview({
  mroWorkOrder,
  updateFlyout,
  closeFlyout,
  updateFlyoutMini,
  ...props
}: {
  mroWorkOrder: GetMroWorkOrderQuery['mroWorkOrder'];
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  updateFlyoutMini?: FlyoutHookReturn['updateFlyout'];
}) {
  const [activeStatus, setActiveStatus] = useState('In Progress');
  const [modal, setModal] = useState<string | false>(false);
  const [updateWorkOrderItem] = useMutation(UpdateMroWorkOrderItemDocument);
  const [deleteCategory] = useMutation(DeleteCategoryDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: mroWorkOrder?.id } }],
  });
  const navigate = useNavigate();
  const tabs = ['In Progress', 'Completed', 'All'];

  const tableData = React.useMemo(
    () =>
      mroWorkOrder?.itemCategories?.reduce((acc, cur) => {
        acc[cur.id] = cur.mroWorkOrderItems.filter((item) => {
          if (item.parentId) return false;
          if (activeStatus === 'All') return true;
          const status = item.status?.toUpperCase()?.replaceAll(' ', '_');
          if (activeStatus === 'In Progress' && status !== 'COMPLETED' && status !== 'ABORT') return true;
          if (activeStatus === 'Completed' && status === 'COMPLETED') return true;
          return false;
        });
        return acc;
      }, {}) ?? {},
    [mroWorkOrder, activeStatus]
  );
  const columns = React.useMemo(() => {
    const column = [
      {
        Header: '',
        id: 'expander',
        className: 'w-4 relative z-[101]',
        Cell: ({ row }) => {
          return (
            <span className="flex items-center" {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <ChevronDownIcon className="h-3 w-3 text-slate-500 hover:text-brand-electric" />
              ) : (
                <ChevronRightIcon className="h-3 w-3 text-slate-500 hover:text-brand-electric" />
              )}
            </span>
          );
        },
      },
      {
        Header: '#',
        accessor: 'itemNumber',
        className: 'w-[40px]',
        Cell: ({ value, row }) => {
          return (
            <span className="flex items-center font-bold">
              {row.original?.categoryNumber ?? ''}.{value}
            </span>
          );
        },
      },
      {
        Header: 'Squawks',
        accessor: 'title', // accessor is the "key" in the data
        className: 'w-4/12',
        Cell: ({ row, value }) => {
          let childrenNum = row.original.children.length;
          return (
            <button className="text-left font-semibold truncate -mx-4 px-4 -my-3 py-3" title={value}>
              <span className={row?.original?.status === 'ABORT' && 'line-through'}>{value}</span>
              {childrenNum > 0 && <span className="ml-1 text-xs text-slate-500">({childrenNum})</span>}
            </button>
          );
        },
      },
      {
        Header: 'Sign Offs',
        accessor: (items) => items.mroWorkOrderSignoffs,
        // accessor is the "key" in the data
        className: 'w-1/12 justify-center',
        Cell: ({ row, value }) => {
          return (
            <div className="ml-1">
              <span className="font-semibold">{value.filter((item) => item.status === 'SIGNED').length}</span>
              <span>/{value.length}</span>
            </div>
          );
        },
      },
      {
        Header: 'Parts',
        accessor: (items) => items.mroWorkOrderParts,
        // accessor is the "key" in the data
        className: 'w-1/12 justify-center',
        Cell: ({ row, value }) => {
          return (
            <div className="relative">
              {value.filter((item) => item.status === 'READY_FOR_PICKUP').length > 0 && (
                <span className="absolute flex h-3 w-3 right-[-10px] top-[-5px]">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-brand"></span>
                </span>
              )}
              <span className="font-semibold">{value.filter((item) => item.status === 'INSTALLED').length}</span>
              <span>/{value.filter((item) => item.status !== 'VOID').length}</span>
            </div>
          );
        },
      },
      {
        Header: 'Assignees',
        accessor: 'assignees',
        className: 'w-1/12',
        Cell: ({ value, row }: any) => {
          if (!Array.isArray(value)) {
            value = [value];
          }
          return (
            <div className="flex -ml-[0.125rem] -my-[0.125rem]">
              {value.map((item, index) => {
                if (!item) return null;
                return (
                  <UserIcon
                    key={index}
                    iconSize="sm"
                    iconText={item.firstName + ' ' + item.lastName}
                    iconId={item.firstName + ' ' + item.lastName}
                    iconColor={item.profileColor}
                    className="border-2 border-slate-50"
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        Header: 'Hours',
        accessor: 'totalLaborHours',
        className: 'w-1/12',
        Cell: ({ row, value }) => {
          return (
            <button className="w-full text-left -mx-4 px-4 -my-3 py-3 font-semibold">
              {formatToFixed(value)}
              <span className="text-xs text-slate-500 ml-0.5 font-medium">/{row.original.estimatedHours}</span>
            </button>
          );
        },
      },
      {
        Header: 'Labor',
        accessor: 'totalLaborBillable',
        className: 'w-1/12 min-w-[5rem]',
        Cell: ({ row, value }) => {
          return <button className="w-full text-left -mx-4 px-4 -my-3 py-3 font-semibold">${formatToFixed(value)}</button>;
        },
      },
      {
        Header: 'Parts',
        accessor: 'totalPartsBillable',
        className: 'w-1/12 min-w-[5rem]',
        Cell: ({ row, value }) => {
          return <button className="w-full text-left -mx-4 px-4 -my-3 py-3 font-semibold">${formatToFixed(value)}</button>;
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'shrink-0 w-48',
        Cell: ({ value, row }: any) => {
          return (
            <StatusButton
              readOnly={false}
              context="task"
              text={value}
              className=""
              onSelect={(status) =>
                updateWorkOrderItem({
                  variables: {
                    input: {
                      id: row.original.id,
                      status,
                    },
                  },
                })
              }
            />
          );
        },
      },
    ];
    return column;
  }, [updateWorkOrderItem]);
  return (
    <>
      {/* USER CARD AND INFO */}
      {modal && (
        <Modal
          cancel={setModal}
          accept={() => deleteCategory({ variables: { id: modal } })}
          message="Deleting a category will delete all of it's contents."
        />
      )}
      {/* USER CARD AND INFO */}
      <div className="p-2 rounded-tr bg-white"></div>
      <div className="flex p-4">
        <div className="flex flex-col w-1/2 border rounded bg-slate-50 p-4">
          <UserCard
            onClick={() => mroWorkOrder?.mroCustomer.id && navigate('/mro/customers/' + mroWorkOrder?.mroCustomer?.id)}
            firstName={mroWorkOrder?.primaryContact?.firstName}
            lastName={mroWorkOrder?.primaryContact?.lastName}
            phoneNumber={mroWorkOrder?.primaryContact?.phoneNumbers?.primary}
            name={mroWorkOrder?.mroCustomer?.name}
            email={mroWorkOrder?.primaryContact?.email}
            updateFlyoutMini={updateFlyoutMini}
            customerId={mroWorkOrder?.mroCustomer?.id}
            mroWorkOrderId={mroWorkOrder?.id ?? ''}
          />
          <div className="flex justify-between mb-2">
            <label className="w-48 font-semibold text-sm text-slate-500">Arrival Date</label>
            <span className="text-sm font-semibold text-brand-dark">{formatApiDate(mroWorkOrder?.arrivalDate)}</span>
          </div>
          <div className="flex justify-between">
            <label className="w-48 font-semibold text-sm text-slate-500">Promise Date</label>
            <span className="text-sm font-semibold text-brand-dark">{formatApiDate(mroWorkOrder?.promiseDate)}</span>
          </div>
        </div>
        <div className="p-4 w-1/2">
          <div className="flex flex-col">
            <span className="font-semibold text-xs uppercase text-slate-500">Primary Concern</span>
            <span className="text-lg font-bold text-brand-dark leading-5">{mroWorkOrder?.primaryConcern}</span>
          </div>
          <div className="flex flex-col mt-4">
            <span className="font-semibold text-xs text-slate-500 uppercase">Notes</span>
            <p className="font-medium text-brand-dark rounded text-sm">{mroWorkOrder?.notes}</p>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div className="flex justify-between items-center border-y border-slate-200 px-4 pt-1 bg-slate-50">
        <Tabs items={tabs} setActiveItem={setActiveStatus} activeItem={activeStatus} />
      </div>
      <div className="bg-slate-100 shadow-inner rounded-b">
        <div className="flex flex-col p-4">
          {mroWorkOrder?.itemCategories?.map((category) => (
            <Category
              key={category.id}
              category={category}
              updateFlyout={updateFlyout}
              closeFlyout={closeFlyout}
              tableData={tableData}
              context={'page'}
              mroWorkOrder={mroWorkOrder}
              columns={columns}
              updateWorkOrderItem={updateWorkOrderItem}
              setModal={setModal}
            />
          ))}

          {mroWorkOrder?.itemCategories?.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-6">
              <button
                className="rounded-b flex items-center font-semibold text-brand cursor-pointer text-sm bg-white p-2 rounded border border-slate-200 shadow-blue hover:bg-brand-offwhite hover:border-brand-pale hover:text-brand-electric transition"
                onClick={() =>
                  updateFlyout({
                    title: 'Create Category',
                    content: (
                      <NewCategoryFlyout
                        closeFlyout={closeFlyout}
                        workOrderId={mroWorkOrder.id}
                        mroCraftId={mroWorkOrder.mroCraft.id}
                        itemCategories={mroWorkOrder?.itemCategories}
                      />
                    ),
                  })
                }>
                <PlusIcon className="h-2.5 w-2.5 mr-1" />
                Create First Squawk
              </button>
            </div>
          ) : (
            <button
              className="self-end"
              onClick={() =>
                updateFlyout({
                  title: 'Create Category',
                  content: (
                    <NewCategoryFlyout
                      closeFlyout={closeFlyout}
                      workOrderId={mroWorkOrder.id}
                      mroCraftId={mroWorkOrder.mroCraft.id}
                      itemCategories={mroWorkOrder?.itemCategories}
                    />
                  ),
                })
              }>
              <span className="text-brand-dark hover:text-brand-electric text-xs font-semibold relative z-20 px-4 py-2 border border-slate-200 hover:border-brand-pale rounded bg-white hover:bg-brand-offwhite transition">
                + Add Category
              </span>
            </button>
          )}
        </div>
        <div className="bg-white border-t border-slate-300 rounded-b p-2 w-full"></div>
      </div>
    </>
  );
}
