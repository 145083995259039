import { Fragment, useContext } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Bars3BottomRightIcon } from '@heroicons/react/24/outline';
import logo from '../../../assets/icons/logo_navbar_w.svg';
import { useSession } from 'contexts';
import { AbilityContext } from 'contexts/AbilityContext';
import { OrgTypes } from 'utils/orgTypes';
import UserMenu from './UserMenu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BarSide(props) {
  const { organizationType } = useSession();
  const ability = useContext(AbilityContext);
  const href = useLocation().pathname;
  
  return (
    <>
      <div
        className={`${
          props.sidebarOpen ? 'w-0 md:w-72' : 'w-12'
        } transition-all ease-in-out duration-300 h-screen bg-brand-dark fixed z-40 flex-col justify-between hidden md:flex`}>
        <div className="w-full py-6">
          <div
            className={`${
              props.sidebarOpen ? 'px-6' : 'px-3'
            } flex justify-between items-center mb-8 w-full transition-all ease-in-out duration-300`}>
            <Link to={organizationType === OrgTypes.PART145 ? '/mro/dashboard' : '/crafts'} className={`${props.sidebarOpen ? '' : 'w-0'} h-10`}>
              <img src={logo} className="h-10" alt="" />
            </Link>
            <Bars3BottomRightIcon
              className="h-6 w-6 text-white"
              aria-hidden="true"
              onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
            />
          </div>
          <nav className={`${props.sidebarOpen ? '' : ''} flex flex-col w-full`}>
            {props.navigation.map((item) => {
              if (item.hidden === true) return null;
              item.active =
                href === item.href ||
                (item.href !== '/aircraft' && href.replace('/aircraft', '').includes(item.href.replace('/aircraft', '')));
              return ( 
                <div key={item.name}>
                { ( ability.can(item.action, item.subject) || !item.action ) &&
                  <Link
                    to={item.href}
                    className={`
                      ${
                        item.active
                          ? 'text-white bg-brand font-bold border-brand-electric'
                          : 'font-semibold text-brand-pale border-brand-dark'
                      } 
                      ${item.subMenu && props.sidebarOpen ? 'pl-20' : ''} 
                      ${props.sidebarOpen ? 'hover:border-brand-electric px-6 transition-all ease-in-out justify-between' : ''} 
                      hover:text-white hover:bg-brand/50 flex items-center border-r-4 h-12 relative cursor-pointer text-sm
                    `}
                    key={item.name}>
                    <div className="flex">
                      {item.active ? (
                        <item.iconActive
                          className={`${props.sidebarOpen ? '' : 'absolute left-3'} ${item.active ? 'text-white' : ''} w-5 h-5 mr-2`}
                          aria-hidden="true"
                        />
                      ) : (
                        <item.icon
                          className={`${props.sidebarOpen ? '' : 'absolute left-3'} ${item.active ? 'text-white' : ''} w-5 h-5 mr-2`}
                          aria-hidden="true"
                        />
                      )}
                      <span
                        className={`${
                          props.sidebarOpen ? 'opacity-100 transition-all ease-in-out duration-300' : 'opacity-0'
                        } whitespace-nowrap`}>
                        {item.name}
                      </span>
                    </div>
                    {props.sidebarOpen && <span>{item.badge ?? ''}</span>}
                  </Link>
                }
                </div>
              );
            })}
          </nav>
          <UserMenu altLoginUrl={props.altLoginUrl} sidebarOpen={props.sidebarOpen} />
        </div>
      </div>
    </>
  );
}
