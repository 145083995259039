import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect, FullFormikTextarea } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CraftType, CreateCraftDocument, GetCraftsDocument, LogType, LookupTailDocument, VolumeMeasurement } from 'graphql/generated';
import { useCallback } from 'react';
import { useSession } from 'contexts';;

const initialValues = {
  name:'',
  tailNumber: '',
  position: '',
  make:'',
  model:'',
  fuelUnit: VolumeMeasurement.Gallons,
  year: '',
  homeBase:'',
  regAddress: '',
  type: CraftType.Singleengine,
  loggingOption: LogType.Loghobbs,
  fuelCapacity:50,
};



export const AircraftSchema = Yup.object().shape({
  tailNumber: Yup.string().required("Required"),
  homeBase: Yup.string().required("Required"),
  make: Yup.string().required("Required"),
  model: Yup.string().required("Required"),
  year: Yup.number().required("Required").typeError("Must be a number"),
  fuelCapacity: Yup.number().required("Required").typeError("Must be a number"),
});


const NewAircraft = function ({ closeFlyout }: { closeFlyout: () => void }) {
  const { user, organizationId } = useSession();
  const [createCraft] = useMutation(CreateCraftDocument, { refetchQueries: [{ query: GetCraftsDocument, variables: { organizationId } }] });
  const [getTailInfo] = useLazyQuery(LookupTailDocument);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
        createCraft({
            variables: {
                craft: {
                  name: values.name,
                  tailNumber: values.tailNumber,
                  year: Number(values.year),
                  model: values.model,
                  make: values.make,
                  homeBase: values.homeBase,
                  fuelCapacity: values.fuelCapacity,
                  fuelUnit: values.fuelUnit,
                  regAddress: values.regAddress,
                  loggingOption: values.loggingOption,
                  type: values.type,
                  organization: {
                    connect: {
                      id: organizationId,
                    },
                  },
                },
            },
        })
        .then(closeFlyout)
        .catch(console.error);
    },
    [createCraft, user]
  );

  return (
    <div className="p-4">
      <Formik initialValues={initialValues} validationSchema={AircraftSchema} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            {/* AIRCRAFT INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <FullFormikInput required={true}  name="homeBase" label="Homebase Airport *" />
              <FullFormikInput onBlur={ async ()=>{
                var tempTail = values.tailNumber.toUpperCase();
                if (tempTail.startsWith('N')) {
                  tempTail = tempTail.substring(1);
                }
                const {data} = await getTailInfo({ variables: { tailNumber: tempTail } });
                const tailInfo = data?.registrations?.[0];
                if(tailInfo){
                  !values.make && setFieldValue('make', tailInfo.manufacturer);
                  !values.model && setFieldValue('model', tailInfo.model);
                  !values.year && setFieldValue('year', tailInfo.yearMfr);
                }
              }} required={true}  name="tailNumber" label="Tail # *" />
              <FullFormikInput required={true}  name="year" label="Year *" />
              <FullFormikInput required={true}  name="make" label="Make *" />
              <FullFormikInput required={true} name="model" label="Model *" />
              <FullFormikInput name="fuelCapacity" label="Fuel Capacity *" />
              <FullFormikSelect name="fuelUnit" >
                <option value={VolumeMeasurement.Gallons}>Gallons</option>
                <option value={VolumeMeasurement.Liters}>Liters</option>
                <option value={VolumeMeasurement.Pounds}>Pounds</option>
                <option value={VolumeMeasurement.Quarts}>Quarts</option>
              </FullFormikSelect>
              <FullFormikSelect name="type" label="Aircraft Type" >
                <option value={CraftType.Singleengine}>Single Engine</option>
                <option value={CraftType.Multiengine}>Multi Engine</option>
              </FullFormikSelect>
              <FullFormikSelect name="loggingOption" label="Logging Option" >
                <option value={LogType.Logboth}>Log Both</option>
                <option value={LogType.Loghobbs}>Log Hobbs</option>
                <option value={LogType.Logtach}>Log Tach</option>
              </FullFormikSelect>
              <FullFormikTextarea name="regAddress" label="Registration Address" placeholder='Aircraft Registration Address'  />
            </div>

            <div className="flex justify-end items-center mt-4">
              <Button text="Add Aircraft" color="navy" size="sm" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewAircraft;