import { Craft, Reservation } from "graphql/generated";
import TimeSlots, { TimeSlotsType } from "../components/TimeSlots";

function Day({ date, events, crafts, onEventSelected }:{date:Date, events?:Array<Object>, crafts?:Array<Craft>, onEventSelected:(any)=>void}) {
    const [cols, slots] = [[""],48];
    var timeSlots:Array<Array<Date>> = [];
    
    crafts.forEach((craft)=>{
        cols.push(craft.name+"-"+craft.tailNumber);
    });

    cols.forEach((item, index)=>{
        var col = [];
        var currentDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var day = new Date(currentDay.getTime() - (30*60000) ); // -30 min to start at 12 am
        for (let slot = 0; slot < slots; slot++) {
            day = new Date(day.getTime() + 30*60000); //add 30 mins slots
            col.push(day); 
        }
        timeSlots[index] = col;
    });

    return (
        <div className="py-5 bg-white px-5 overflow-x-auto w-full items-center grid grid-col-7 rounded-b border-slate-300 shadow-blue">
            {/* days of week */}
            <div className="flex text-md rounded-t border border-brand">
                {cols.map((value, index, arr)=>{
                    return(<div className={(index===arr.length-1?"border-transparent ":"")+"border-r border-brand w-full flex justify-center py-2 "+((index===0)?"flex-1":"min-w-[100px] flex-[1.7]")} key={index}>
                        {value}
                    </div>);
                })}
            </div>
            {/* times of days */}
            <TimeSlots data={timeSlots} events={events} crafts={crafts} onClick={onEventSelected} type={TimeSlotsType.Day} />
        </div>
    );
  }
  
  export default Day;