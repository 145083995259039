import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InboxIcon, LinkIcon } from '@heroicons/react/24/solid';
import FlyoutMini from 'components/FlyoutMini/FlyoutMini';
import { Flyout, useFlyout } from 'components/Flyout/Flyout';
import StatusButton from 'components/StatusButton/StatusButton';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetServiceRequestDocument,
  UpdateServiceRequestDocument,
  GetServiceRequestBadgesDocument,
} from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { PlusIcon } from 'assets/icons';
import { formatApiDate } from 'utils/formatter';
import { useSession } from 'contexts';
import UserCard from 'components/UserCard/UserCard';

const ServiceRequest = function () {
  const { flyoutProps } = useFlyout();
  const { flyoutProps: flyoutMiniProps, updateFlyout: updateFlyoutMini } = useFlyout();
  const { serviceRequestId } = useParams();
  const { user } = useSession();
  const navigate = useNavigate();
  const { data: { mroServiceRequest } = {} } = useQuery(GetServiceRequestDocument, {
    variables: { id: serviceRequestId },
  });
  const [updateServiceRequest] = useMutation(UpdateServiceRequestDocument, {
    refetchQueries: [
      { query: GetServiceRequestDocument, variables: { id: serviceRequestId } },
      { query: GetServiceRequestBadgesDocument, variables: { mroOrganizationId: user.mroOrganizationId } },
    ],
  });
  const [isSubmitting, setSubmitting] = useState(false);

  const createAndOpenWorkOrder = async () => {
    if (!mroServiceRequest || isSubmitting) {
      return;
    }
    setSubmitting(true);
    updateServiceRequest({
      variables: {
        input: {
          id: mroServiceRequest?.id,
          status: 'CLOSED',
        },
      },
    });
    navigate('/mro/work-orders/new/' + mroServiceRequest?.id);
  };

  return (
    <>
      <FlyoutMini {...flyoutMiniProps} />
      <Flyout {...flyoutProps} />
      <div className="bg-white border rounded border-slate-300 shadow-blue">
        {/* HEADER SECTION */}
        <div className="flex flex-col">
          <div className="flex rounded-t items-center justify-between p-6">
            <div className="flex items-center">
              <InboxIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
              <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">{mroServiceRequest?.title}</h1>
            </div>
            <div className="flex items-stretch gap-2">
              <StatusButton
                readOnly={false}
                context="request"
                text={mroServiceRequest?.status}
                className=""
                onSelect={(status) =>
                  updateServiceRequest({
                    variables: {
                      input: {
                        id: mroServiceRequest?.id,
                        status,
                      },
                    },
                  })
                }
              />
              {mroServiceRequest?.mroWorkOrderId ? (
                <button
                  onClick={() => navigate('/mro/work-orders/' + mroServiceRequest.mroWorkOrderId)}
                  className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
                  <LinkIcon className="h-3 w-3 mr-1" />
                  View Work Order
                </button>
              ) : (
                <button
                  onClick={createAndOpenWorkOrder}
                  className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
                  <PlusIcon className="h-3 w-3 mr-1" />
                  Create Work Order
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-between items-end p-4 px-6 bg-slate-50 border-slate-300 border-t rounded-b w-full overflow-hidden">
            <ul className="flex">
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroServiceRequest?.mroCraft?.tailNumber}</h3>
                <span className="text-xs text-slate-500">TAIL #</span>
              </li>
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroServiceRequest?.mroCraft?.year}</h3>
                <span className="text-xs text-slate-500">YEAR</span>
              </li>
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroServiceRequest?.mroCraft?.make}</h3>
                <span className="text-xs text-slate-500">MAKE</span>
              </li>
              <li className="flex-col mr-4">
                <h3 className="font-bold text-brand-dark -mb-1">{mroServiceRequest?.mroCraft?.model}</h3>
                <span className="text-xs text-slate-500">MODEL</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-white border rounded-b rounded-r border-slate-300 shadow-blue my-5">
        {/* USER CARD AND INFO */}
        <div className="flex p-4">
          <div className="flex flex-col w-1/2 border rounded bg-slate-50 p-4">
            <UserCard
              onClick={() => mroServiceRequest?.mroCustomer.id && navigate('/mro/customers/' + mroServiceRequest?.mroCustomer.id)}
              firstName={mroServiceRequest?.mroCustomer?.mroContacts[0]?.firstName}
              lastName={mroServiceRequest?.mroCustomer?.mroContacts[0]?.lastName}
              name={mroServiceRequest?.mroCustomer.name}
              email={mroServiceRequest?.mroCustomer.email}
              phoneNumber={mroServiceRequest?.mroCustomer?.mroContacts[0]?.phoneNumbers?.primary}
              customerId={mroServiceRequest?.mroCustomer.id}
              mroWorkOrderId={mroServiceRequest?.mroWorkOrderId ?? ''}
              updateFlyoutMini={updateFlyoutMini}
            />
            <div className="flex justify-between mb-2">
              <label className="w-48 font-semibold text-sm text-slate-500">Request Date</label>
              <span className="text-sm font-semibold text-brand-dark">{formatApiDate(mroServiceRequest?.createdAt)}</span>
            </div>
          </div>
          <div className="p-4 w-1/2">
            <div className="flex flex-col">
              <span className="font-semibold text-xs uppercase text-slate-500">Primary Concern</span>
              <span className="text-lg font-bold text-brand-dark leading-5">{mroServiceRequest?.title}</span>
            </div>
            {mroServiceRequest?.location && (
              <div className="flex flex-col">
                <span className="font-semibold text-xs uppercase text-slate-500">Location</span>
                <span className="text-medium font-bold text-brand-dark leading-5">{mroServiceRequest?.location}</span>
              </div>
            )}

            <div className="flex flex-col mt-4">
              <span className="font-semibold text-xs text-slate-500 uppercase">Notes</span>
              <p className="font-medium text-brand-dark rounded text-sm">{mroServiceRequest?.notes}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceRequest;
