import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { ShareIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';

async function fetchFileAndGetName(uri) {
  try {
      const response = await fetch(uri);
      if (!response.ok) {
          throw new Error('Failed to fetch file');
      }

      // Get filename from Content-Disposition header
      let fileName = '';
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition && contentDisposition.includes('filename=')) {
          fileName = contentDisposition
              .split('filename=')[1]
              .replace(/['"]/g, '')
              .trim();
      } else {
          // If Content-Disposition header is not present, try to get filename from URL
          const urlParts = uri.split('/');
          fileName = urlParts[urlParts.length - 1];
      }

      const blob = await response.blob();
      const file = new File([blob], fileName, { type: blob.type });

      return file;
  } catch (error) {
      console.error('Error fetching file:', error);
      throw error;
  }
}


export default function DocumentViewer({ close, uri, fileType }) {
  const [file, setFile] = useState(null);
  useEffect(() => {
    fetchFileAndGetName(uri).then((f) => setFile(f));
  }
  , [uri]);

  return (
    <div className="fixed flex items-center justify-center h-full w-full bottom-0 left-0 z-50">
      <div
        onClick={() => {
          
        }}
        className="bg-slate-100/50 backdrop-blur w-full h-full absolute top-0 left-0">
          <ShareIcon
          onClick={async () => {
            try {
              await navigator.share({ title:"Document From Coflyt", url:uri , text: "Check out this document from Coflyt"}) 
            } catch (error) {
              console.log(error);
            }
          }}
          className="h-8 w-8 md:h-10 md:w-10 absolute top-0 md:top-2 right-20 text-slate-500 cursor-pointer transition z-[60]"
          />
          <XMarkIcon
          onClick={() => {
            close();
          }}
          className="h-8 w-8 md:h-10 md:w-10 absolute top-0 md:top-2 right-2 text-slate-500 cursor-pointer transition z-[60]"
          />
          <DocViewer  documents={[ { uri, fileType: 'png' } ]} pluginRenderers={DocViewerRenderers} />
        </div>
    </div>
  );
}
