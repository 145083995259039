import { useState, useEffect } from 'react';
import {
  PencilIcon,
  Cog6ToothIcon,
  PlusIcon as PlusSm,
  BoltIcon,
  FlagIcon,
  PlusIcon,
  SignalIcon,
  WrenchIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { Table } from 'components/Table/WorkOrderTable';
import NewItemFlyout from '../WorkOrderItems/NewItemFlyout';
import { IconDropdown, FullFormikInput } from 'components/Form/StandardForm';
import { Form, Formik } from 'formik';
import { DeleteCategoryDocument, GetMroWorkOrderDocument, UpdateCategoryDocument } from 'graphql/generated';
import { useMutation } from '@apollo/client';
import { EngineIcon, AirplaneIcon, SoundwaveIcon, StatisticsIcon } from 'assets/icons';
import { formatUSD, formatToFixed } from 'utils/formatter';
import EditCategoryFlyout from './EditCategoryFlyout';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import EditComponent from '../WorkOrderItems/EditComponent';
import Modal from 'components/Modal/Modal';

const icoStyle = 'h-6 w-6 p-1 bg-brand-pale text-brand-electric shadow rounded-sm';

const iconObj = {
  BoltIcon: <BoltIcon className={icoStyle} />,
  Cog6ToothIcon: <Cog6ToothIcon className={icoStyle} />,
  FlagIcon: <FlagIcon className={icoStyle} />,
  SignalIcon: <SignalIcon className={icoStyle} />,
  WrenchIcon: <WrenchIcon className={icoStyle} />,
  EngineIcon: <EngineIcon className={icoStyle} />,
  AirplaneIcon: <AirplaneIcon className={icoStyle} />,
  SoundwaveIcon: <SoundwaveIcon className={icoStyle} />,
  StatisticsIcon: <StatisticsIcon className={icoStyle} />,
};

export default function Category({
  category,
  updateFlyout,
  closeFlyout,
  tableData,
  mroWorkOrder,
  columns,
  updateWorkOrderItem,
  setModal,
  context,
}: {
  category: any;
  updateFlyout: any;
  closeFlyout: any;
  tableData: any;
  mroWorkOrder: any;
  columns: any;
  updateWorkOrderItem: any;
  setModal?: any;
  context: string;
}) {
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalLaborBillable, setTotalLaborBillable] = useState(0);
  const [totalPartsBillable, setTotalPartsBillable] = useState(0);
  const [totalBillable, setTotalBillable] = useState(0);
  const [totalLaborHours, setTotalLaborHours] = useState(0);
  const [mutate] = useMutation(UpdateCategoryDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: mroWorkOrder.id } }],
  });
  const [deleteCategory] = useMutation(DeleteCategoryDocument, {
    refetchQueries: [{ query: GetMroWorkOrderDocument, variables: { id: mroWorkOrder.id } }],
  });
  const handleSubmit = function (values: { iconName: string; category: string }) {
    mutate({
      variables: {
        input: {
          id: category.id,
          title: values.category,
          iconName: values.iconName,
        },
      },
    });
    setEdit(false);
  };
  useEffect(() => {
    setTotalLaborBillable(tableData[category.id].reduce((acc, cur) => acc + cur.totalLaborBillable, 0));
    setTotalPartsBillable(tableData[category.id].reduce((acc, cur) => acc + cur.totalPartsBillable, 0));
    setTotalBillable(tableData[category.id].reduce((acc, cur) => acc + cur.totalPartsBillable + cur.totalLaborBillable, 0));
    setTotalLaborHours(tableData[category.id].reduce((acc, cur) => acc + cur.totalLaborHours, 0));
  }, [tableData, category]);
  return (
    <>
      { showModal && <Modal message={`Are you sure you want to delete the ${category.title} category?`} 
      accept={() => {
        deleteCategory({
          variables: {
            id: category.id,
          },
        });
        setShowModal(false);
      }}
      cancel={setShowModal} /> }
      <div className="shadow-blue bg-white rounded mb-4">
        <div className="flex items-center justify-between p-4 border border-slate-200 rounded-t border-b-0">
          {!edit ? (
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-2">
                {iconObj[category.iconName ?? 'Cog6ToothIcon']}
                <h3 className="font-bold">{category.title}</h3>
                {category?.categoryNumber && (
                  <span className="bg-brand-pale text-brand text-sm font-medium px-1.5 py-1 rounded-lg">
                    {'#' + category?.categoryNumber}
                  </span>
                )}
              </div>
              {context === 'page' && (
                <div className="flex items-center gap-4 text-slate-500 text-xs font-bold">
                  {category.mroComponent && (
                    <div
                      className="flex bg-white justify-center items-center rounded w-auto cursor-pointer hover:text-brand-electric transition"
                      onClick={() =>
                        updateFlyout({
                          title: 'Manage Component',
                          content: (
                            <EditComponent closeFlyout={closeFlyout} workOrderId={mroWorkOrder.id} component={category?.mroComponent} />
                          ),
                        })
                      }>
                      <WrenchScrewdriverIcon className="h-3 w-3 mr-0.5" />
                      Component {category?.mroComponent?.serialNumber && '(' + category.mroComponent.serialNumber + ')'}
                    </div>
                  )}
                  <div
                    className="flex bg-white justify-center items-center rounded w-auto cursor-pointer hover:text-brand-electric transition"
                    onClick={() =>
                      updateFlyout({
                        title: 'Add Squawk',
                        content: <NewItemFlyout closeFlyout={closeFlyout} workOrderId={mroWorkOrder.id} categoryId={category.id} />,
                      })
                    }>
                    <PlusSm className="h-3 w-3 mr-0.5" />
                    Add
                  </div>
                  <button
                    onClick={() => {
                      updateFlyout({
                        title: 'Edit Category',
                        content: <EditCategoryFlyout categoryId={category.id} closeFlyout={closeFlyout} />,
                      });
                    }}
                    className="flex font-bold justify-center items-center cursor-pointer hover:text-brand-electric">
                    <PencilIcon className="h-2.5 w-2.5 mr-1" />
                    Edit
                  </button>
                  {tableData[category.id].filter((data) => data?.status !== "ABORT" )?.length == 0 && <button
                    onClick={() => {
                      setShowModal(true)
                    }}
                    className="flex font-bold justify-center items-center cursor-pointer hover:text-brand-electric">
                    <TrashIcon className="h-2.5 w-2.5 mr-1" />
                    Delete
                  </button> }
                </div>
              )}
            </div>
          ) : (
            <Formik enableReinitialize initialValues={{ category: category.title, iconName: category.iconName }} onSubmit={handleSubmit}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="w-full">
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-2 w-full">
                      <IconDropdown iconName={values.iconName} setIconName={(name: string) => setFieldValue('iconName', name)} />
                      <FullFormikInput name="category" className="-my-3 mt-0 w-1/2" />
                    </div>
                    <div className="flex items-center gap-4 text-slate-500 text-xs font-bold">
                      <button
                        className="flex bg-white justify-center items-center rounded w-auto cursor-pointer transition text-brand-electric font-semibold"
                        type="submit">
                        Save
                      </button>
                      <button
                        onClick={() => {
                          setEdit(!edit);
                        }}
                        className="flex font-semibold justify-center items-center cursor-pointer text-slate-500">
                        Cancel
                      </button>
                      {category.mroWorkOrderItems.length === 0 && (
                        <TrashIcon
                          onClick={() => {
                            setModal(category.id);
                          }}
                          className="h-3.5 w-3.5 text-rose-500 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className="border border-slate-200 border-t-brand-pale">
          <Table
            columns={columns}
            context="page"
            data={tableData[category.id]
              .sort((a, b) => a.itemNumber - b.itemNumber)
              .map((data) => {
                const tempData = { ...data, logEntryId: category?.mroLogEntryId, categoryNumber: category?.categoryNumber ?? 0 };
                return tempData as any;
              })}
            updateFlyout={updateFlyout}
            updateMroWorkOrderItem={updateWorkOrderItem}
            workOrderId={mroWorkOrder.id}
            className="min-w-max flex flex-col sm-head"
            closeFlyout={closeFlyout}
          />
          {tableData[category.id].length === 0 && context === 'page' && (
            <div className="flex items-center justify-center my-3 w-full px-4">
              <button
                className="flex items-center justify-center gap-0.5 text-xs text-center font-medium cursor-pointer rounded hover:bg-slate-50/50 bg-slate-50 py-1.5 border-slate-300 w-full border border-dashed transition"
                onClick={() =>
                  updateFlyout({
                    title: 'Add Squawk',
                    content: <NewItemFlyout closeFlyout={closeFlyout} workOrderId={mroWorkOrder.id} categoryId={category.id} />,
                  })
                }>
                <PlusIcon className="h-3 w-3" />
                Create A Squawk
              </button>
            </div>
          )}
        </div>
        <div className="flex gap-4 items-center justify-end border border-slate-200 rounded-b border-t-0 px-4 py-1.5 text-sm pl-12">
          <div className="flex items-center gap-2">
            <span className="font-bold text-brand">Hours:</span>
            <span className="font-semibold">{formatToFixed(totalLaborHours)}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="font-bold text-brand">Labor:</span>
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded">
              {formatUSD(totalLaborBillable)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="font-bold text-brand">Parts:</span>
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded">
              {formatUSD(totalPartsBillable)}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <span className="font-bold text-brand">Total:</span>
            <span className="font-bold text-brand bg-brand-offwhite border border-brand-pale px-1 rounded">
              {formatUSD(totalBillable)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
