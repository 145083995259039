//Example invocation
//<TabCards items={[{title:'New Check', content: <span>Test</span> }, {title:'Logs', content: <span>Test2</span> }]} setActiveItem={setActiveItem} activeItem={activeItem} />

export interface TabCardItem {
  title: string;
  content: React.ReactNode;
  hidden?: boolean;
}

export default function TabCards({ items, setActiveTitle, activeTitle, addBars = true, readOnly = false, ...props }
  : {
    items?: TabCardItem[];
    setActiveTitle: React.Dispatch<React.SetStateAction<string>>;
    activeTitle: string;
    addBars?: boolean;
    readOnly?: boolean;
  } ) {
  let tabItems = items || [];
  return (
    <div>
      <div className="flex">
        {tabItems.map((item, index) => {
          if (item.hidden) return null;
          return (
            <button
              key={index}
              className={`${
                activeTitle !== item.title ? 'bg-slate-50 text-slate-500' : 'bg-white border-b-white'
              } text-sm p-2 md:px-4 font-semibold rounded-t border border-slate-300 -mb-[2px]`}
              disabled={activeTitle === item.title || readOnly}
              onClick={() => setActiveTitle(item.title)}>
              {item.title}
            </button>
          );
        })}
      </div>
      <div className="bg-slate-100 border rounded-b rounded-r border-slate-300 shadow-blue">
        {addBars && <div className="p-2 rounded-tr bg-white" />}
        {items.find(item => activeTitle === item.title )?.content}
        {addBars && <div className="p-2 rounded-tr bg-white" />}
      </div>
    </div>
  );
}
