import { PrinterIcon, ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { MroInvoice } from 'graphql/generated';
import { formatApiDate, formatUSD, formatToFixed } from 'utils/formatter';

// NOTES FOR THOMAS
// We need to ensure the MRO has a dark and a light logo, we use a light one on service request and a dark one here.
// Currently im only inverting the light one which isnt great, just a heads up. Remove the "invert" class on this page.
//
// "Additional Charges" section may need to be switched to a Data Table with configurable fields per MRO.
// I imagine they MROs dont charge consistently.
// If we do that we might need to change how that is formatted to account for variable width and number of columns.

const Invoice = function ({ invoice }: { invoice: MroInvoice }) {
  const sampleLogo = '/static/media/victory_lane.35317393f6847c88b1cec8781ceedf37.svg';

  return (
    <div className="flex flex-col p-4 pb-64">
      {/* REMOVE UNTIL AFTER MEETING */}
      <div className="flex gap-1 justify-end mb-2">
        <PrinterIcon className="h-6 w-10 p-[0.1875rem] rounded bg-slate-200 text-slate-500" />
        <ArrowDownTrayIcon className="h-6 w-10 p-[0.1875rem] rounded bg-brand-pale text-brand-electric" />
      </div>
      <div className="bg-white rounded shadow border border-slate-200 p-8">
        {/* HEADER */}
        <div className="flex justify-between w-full mb-2">
          <div className="flex flex-col w-1/2">
            <div className="flex justify-between items-center">
              <h1 className="text-brand-dark font-bold text-3xl">
                <span className="text-slate-500 font-normal">#</span>
                {invoice.invoiceNumber}
              </h1>
              <div className="flex flex-col items-end text-sm">
                <span className="text-xs text-slate-600">Issued</span>
                <span className="text-brand-dark font-bold -mt-0.5">{formatApiDate(invoice.invoiceDate)}</span>
              </div>
            </div>
            <div className="flex flex-col bg-slate-100 p-4 rounded my-2 text-sm">
              <span className="font-bold text-brand-dark text-base mb-1">{invoice.customer.name}</span>
              {/* <span>{sampleData[0].customer.email}</span>
              <span>{sampleData[0].customer.phone}</span> */}
              <span>{invoice.customer.address1}</span>
              <span>{invoice.customer.address2}</span>
              <span>
                {invoice.customer.craft.year +
                  ' ' +
                  invoice.customer.craft.make +
                  ' ' +
                  invoice.customer.craft.model +
                  ', ' +
                  invoice.customer.craft.tail}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-end w-1/2">
            <img className="invert mb-4" src={sampleLogo} alt="" />
            <div className="flex flex-col items-end text-slate-600 font-medium text-sm">
              <span>{invoice.mro.address1}</span>
              <span>{invoice.mro.address2}</span>
              <span>{invoice.mro.phone}</span>
            </div>
          </div>
        </div>
        {/* ITEMS LIST */}
        <div>
          {invoice.items.map((item, index) => {
            return (
              <div className="flex flex-col pt-2 mt-4 first:mt-0" key={index}>
                <div className="flex items-center w-full border-b mb-2 relative border-dashed">
                  <span className="font-bold text-xs text-brand-dark uppercase py-0.5 -mx-4 pl-4">Item {index + 1}</span>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-col text-sm w-2/3">
                    <span className="font-semibold">Discrepancy</span>
                    <span className="-mt-0.5">{item.discrepancy}</span>
                  </div>
                  <div className="flex flex-col text-sm w-1/6 items-end">
                    <span className="font-semibold">Hours</span>
                    <span className="-mt-0.5">{item.laborHours}</span>
                  </div>
                  <div className="flex flex-col text-sm w-1/6 items-end">
                    <span className="font-semibold">Subtotal</span>
                    <span className="-mt-0.5">{formatToFixed(item.laborCost)}</span>
                  </div>
                </div>
                {item.notes && (
                  <div className="flex flex-col text-sm bg-slate-50 border px-4 py-2 mt-2">
                    <span className="text-xs text-brand-dark font-semibold uppercase">Action Taken</span>
                    <span className="font-mono text-xs py-1">{item.notes}</span>
                  </div>
                )}
                <table className={`${item.parts.length === 0 ? '-mt-2 invoice-thead' : 'mt-4'} rounded`}>
                  <thead className="border border-b-0">
                    <tr className="text-left text-sm border-b">
                      <th className="px-2 font-semibold text-right border-r w-12">Qty</th>
                      <th className="px-2 font-semibold w-36">Part #</th>
                      <th className="px-2 font-semibold">Description</th>
                      <th className="px-2 font-semibold text-right">Unit Price</th>
                      <th className="px-2 font-semibold text-right">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody className="border">
                    {item.parts.map((part, ndx) => {
                      return (
                        <tr className="text-sm" key={ndx}>
                          <td className="align-top px-2 text-right border-r font-semibold">{part.quantity}</td>
                          <td className="align-top px-2">{part.partNumber}</td>
                          <td className="align-top px-2">{part.description}</td>
                          <td className="align-top px-2 text-right">{formatToFixed(part.unitPrice)}</td>
                          <td className="align-top px-2 text-right">{formatToFixed(part.subtotal)}</td>
                        </tr>
                      );
                    })}
                    <tr className="text-sm border-t bg-slate-50">
                      <td></td>
                      <td></td>
                      <td className="px-2 font-medium">Parts Cost:</td>
                      <td></td>
                      <td className="px-2 text-right">{formatToFixed(item.partsCost)}</td>
                    </tr>
                    <tr className="text-sm bg-slate-50">
                      <td></td>
                      <td></td>
                      <td className="px-2 font-medium">Labor Cost:</td>
                      <td></td>
                      <td className="px-2 text-right">{formatToFixed(item.laborCost)}</td>
                    </tr>
                    <tr className="text-sm border-t bg-slate-100">
                      <td></td>
                      <td></td>
                      <td className="px-2 font-bold">Item Subtotal:</td>
                      <td></td>
                      <td className="px-2 text-right font-bold">${formatToFixed(item.subTotal)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        {/* TOTAL SECTION */}
        <div className="mt-6">
          <div className="flex items-start mt-2 gap-2">
            <div className="flex flex-col w-2/5 gap-2 text-sm">
              {invoice.charges?.length && (
                <div className="flex flex-wrap border p-2 rounded border-slate-200 border-dashed">
                  <span className="font-semibold border-b border-slate-200 -mt-0.5 pb-1 mb-1.5">Additional Charges</span>
                  {invoice.charges.map((charge, idx) => (
                    <div key={idx} className="w-full">
                      <div className="flex items-center relative">
                        <span className="w-1/2">{charge.description}:</span>
                        <span className="w-1/2 text-right">{formatUSD(charge.total.toString())}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex flex-col border p-2 text-sm h-full rounded border-slate-200 border-dashed">
                <span className="font-semibold border-b border-slate-200 -mt-0.5 pb-1 mb-1.5">Labor Summary</span>
                <div className="flex justify-between">
                  <span>Shop Labor:</span>
                  <span>{formatUSD(invoice.laborSummary.shopLabor)}</span>
                </div>
                {/* <div className="flex justify-between">
                  <span>Outside Labor:</span>
                  <span>{formatUSD(invoice.laborSummary.outsideLabor)}</span>
                </div> */}
                <div className="flex justify-between">
                  <span>Total Hours:</span>
                  <span>{Number(invoice.laborSummary.totalHours).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-3/5 p-2 pb-1 text-sm border border-slate-200 rounded">
              <div className="flex justify-between text-right">
                <span className="w-1/2">Total Labor:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.totalLabor)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Total Parts:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.totalParts)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Total Charges:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.totalCharges)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Shipping:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.totalShipping)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Tax:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.tax)}</span>
              </div>
              <div className="flex justify-between text-right">
                <span className="w-1/2">Amount Due:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.amountDue)}</span>
              </div>
              <div className="flex justify-between text-right border-t border-slate-200 border-dashed mt-0.5 pt-0.5">
                <span className="w-1/2">Deposit:</span>
                <span className="w-1/2">{formatUSD(invoice.priceSummary.deposit)}</span>
              </div>
              <div className="flex justify-between text-right font-bold border-t pt-1 mt-1 text-base bg-slate-100 -mx-2 -mb-1 pr-2 pb-1">
                <span className="w-1/2">Balance Due:</span>
                <span className="w-1/2">{formatUSD(invoice.amount)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
