import { useMutation, useQuery } from '@apollo/client';
import {
  GetInspectionsDocument,
  InspectionDueType,
  GetIntervalsAndLogbooksForCraftDocument,
  CreateInspectionDocument,
  InspectionCreateInput,
  IntervalType,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FormLabel, FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import SegmentControl from 'components/SegmentControl/SegmentControl';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import { format } from 'date-fns';


const NewInspectionFlyout = function ( { closeFlyout, groupId } : { closeFlyout: () => void; groupId: string; } ) {
  const { craftId } = useSession();
  const [createInspection] = useMutation(CreateInspectionDocument, { refetchQueries: [{ query: GetInspectionsDocument, variables: { craftId } }] });
  const { data: { craft : { intervalsNonConsumable: intervals, logBooks } = {} } = {} } = useQuery(GetIntervalsAndLogbooksForCraftDocument, { variables: { craftId } });
  const nonConsumableIntervals = intervals?.filter((interval) => interval.intervalType === IntervalType.Normal);
  const initialValues = {
    name: '',
    description: '',
    airworthinessItem: false,
    dueMethod: 'Date',
    dueDate: format(new Date(), 'yyyy-MM-dd'),
    doesRepeat: true,

    dueEveryDate: '0',
    dateType: 'DAYS',
    warnAtDate: '0',
    
    intervalId: '',
    dueEveryInterval: '0',
    warnAtInterval: '0',

    enableLogbooks: false,
    logbooks: [],
  };

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    const selectInterval = nonConsumableIntervals?.find((interval) => interval.id === values.intervalId) ?? nonConsumableIntervals?.[0];
    const inspectionInput: InspectionCreateInput = {
      name: values.name,
      description: values.description,
      ...(values.dueMethod === 'Date' && { dueDate: values.dueDate }),
      ...( values.dueMethod !== 'Interval' && {
        ...( values.dateType === 'DAYS' && { dueDateDaysFrequency: Number(values.dueEveryDate) }),
        ...( values.dateType === 'MONTHS' && { dueDateMonthsFrequency: Number(values.dueEveryDate) }),
        dueDateSoon: Number(values.warnAtDate),
      }),
      ...( values.dueMethod !== 'Date' && {
        dueInterval: { [selectInterval.name]: { "dueEvery": Number(values.dueEveryInterval), "dueSoon": Number(values.warnAtInterval) } },
        intervalDueRecords: { create: [ { interval: { connect: { id: selectInterval.id } } } ] },
      }),
      airworthinessItem: values.airworthinessItem,
      dueMethod: values.dueMethod.toUpperCase() as InspectionDueType,
      doesRepeat: values.dueMethod === 'Date' ? values.doesRepeat : true,
      inspectionGroup: { connect: { id: groupId } },

      craft: { connect: { id: craftId } },
    };
    await createInspection({ variables: { input: inspectionInput } });
    setSubmitting(false);
    closeFlyout();
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form className='p-2'>
          <Card>
            <div className="flex flex-col md:flex-row gap-2">
              <div className="flex flex-col w-full">
                <FullFormikInput name="name" label="Name*" autoComplete="off" />
                <div className='flex justify-start my-3'>
                    <FullFormikCheckBox isVertical={true} name="airworthinessItem" label="Aircraft is grounded when overdue" />
                </div>
                {/* <hr className="my-2" />
                <div className='flex justify-start my-3'>
                  <FullFormikCheckBox isVertical={true} name="enableLogbooks" label="Enable Auto Logbook Generation" />
                </div>
                <FormLabel className="font-bold text-slate-500">Logbooks</FormLabel>
                <Multiselect disabled={!values.enableLogbooks} initialSelected={[]} items={logBooks?.map((logBook) => ( 
                      { name: logBook.name, value: logBook }
                    ))} setSelectedItems={(items) => setFieldValue('logbooks', items)} /> */}
                <hr className="my-2" />
                <SegmentControl className={'my-2'} activeItem={values.dueMethod} items={['Date', 'Interval', 'Both']} onChange={ (item) => setFieldValue('dueMethod', item) } />
              </div>
            </div>
            { (values.dueMethod !== 'Interval') && <div className='my-2'>
                <span className="font-bold text-brand-dark uppercase">Date</span>
                <hr className="my-2" />
                { values.dueMethod === 'Date' && <div className='flex justify-start mt-3 mb-4'>
                    <FullFormikCheckBox isVertical={true} name="doesRepeat" label="Recurring?" />
                </div> }
                { (values.doesRepeat || values.dueMethod === 'Both') ? <>
                  <div className='flex gap-10'>
                    <FullFormikSelect name="dateType" label="Date Type:" autoComplete="off" >
                      <option value="DAYS">Days</option>
                      <option value="MONTHS">Months</option>
                    </FullFormikSelect>
                    <FullFormikInput name="dueEveryDate" label="Due Every:" autoComplete="off" />
                    <FullFormikInput name="warnAtDate" label="Warn (days):" autoComplete="off" />
                  </div>
                </> : <div className="flex justify-between py-2 border-b border-slate-100 items-center">
                  <FormLabel className="text-sm font-medium text-slate-500">Due Date</FormLabel>
                  <Field as={DateTimePicker} name="dueDate" />                  
                </div> }
            </div> }
            { (values.dueMethod !== 'Date') && <div className='my-2'>
                <span className="font-bold text-brand-dark uppercase">Interval</span>
                <hr className="my-2" />
                <div className='flex gap-10'>
                  <FullFormikSelect name="intervalId" label="Interval Name:" autoComplete="off" >
                    { nonConsumableIntervals?.map( (interval) => <option key={interval.id} value={interval.id}>{interval.name}</option> ) }
                  </FullFormikSelect>
                  <FullFormikInput name="dueEveryInterval" label="Due Every:" autoComplete="off" />
                  <FullFormikInput name="warnAtInterval" label="Warn At:" autoComplete="off" />
                </div>
            </div> }
          </Card>
          <div className="px-4 mt-4 flex items-center justify-end">
            <Button text="Create Inspection" color="navy" size="xs" type='submit' disabled={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewInspectionFlyout;
