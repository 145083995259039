import { Craft, Reservation } from "graphql/generated";
import { areDaysEqual, getMinutePercentage } from "components/Calendar/utils/date";
import { applyTimezone, formatCalendarHour } from "utils/formatter";
import { useSession } from "contexts";

export const eventColors = 
["bg-brand text-white", "bg-brand-electric text-white", "bg-brand-purple text-white",
"bg-lime-500 text-white", "bg-green-500 text-white", "bg-teal-500 text-white", "bg-cyan-500 text-white", "bg-sky-500 text-white", 
"bg-blue-500 text-white", "bg-indigo-500 text-white", "bg-violet-500 text-white", "bg-purple-500 text-white", "bg-fuchsia-500 text-white", "bg-pink-500 text-white", "bg-rose-500 text-white"];

export enum TimeSlotsType {
    Week,
    Day
}

function TimeSlots({ data, events, onClick, type, crafts }:{ crafts?:Craft[], data:Array<Array<Date>>, events?:Array<any>, onClick:(any)=>void, type:TimeSlotsType }){
    var [colorIndex, eventColorMap] = [-1, {}];
    const { user } = useSession();
    events.forEach((event)=>{
        if(!eventColorMap[event.userId]){
            colorIndex += 1;
            eventColorMap[event.userId] = eventColors[colorIndex];
        }
    });   
    const dateConversion = (event: Reservation, field: string)=>{
        return new Date(applyTimezone(event[field], Number(event.timezone),Number(user.timezone)));
    }
    return <div className={"flex rounded-b border-b w-full border-r border-l border-brand"}>
        {data.map((col, colI)=>{
            var didShowTextOnCol = false;
            return <div className={"flex flex-col "+(colI===0?"flex-1":"min-w-[100px] flex-[1.7]")} key={colI}> 
                {col.map((row)=>{
                    return <div className="border flex gap-x-1 h-[26px]" key={row.toISOString()}>
                        {colI===0?
                            <div className="flex w-full">{row.getMinutes()===0? formatCalendarHour(row):"\xa0"}</div>
                            :
                            events.map((event, eventI)=>{
                                const eventRes = (event as Reservation);
                                const [precentShow, rangeType] = getMinutePercentage(row, dateConversion(event, 'from'), dateConversion(event, 'to'));
                                if( (type === TimeSlotsType.Day && crafts[colI-1].id !== eventRes.craftId) ||
                                    (type === TimeSlotsType.Week && precentShow===0 && ( areDaysEqual(row, dateConversion(event, 'to')) || areDaysEqual(row, dateConversion(event, 'from')))) ){
                                    return <div key={row.toISOString()} className="w-full flex">&nbsp;</div>; // we want to show a blank space for each row
                                }else if(precentShow===0){
                                    return null;
                                }                                
                                if(eventColorMap[eventI] === undefined){
                                    colorIndex += 1;
                                    eventColorMap[eventI] = colorIndex;
                                }
                                const precentStyle = `h-[${precentShow<9?"0"+String(precentShow+1):precentShow+1}%]`; //format to precent
                                var buttonClassName = `${eventColorMap[event.userId]} ${rangeType=="from"&& precentShow!==100 ?"mb-[-2px]":"my-[-2px]"} py-3 w-full ${precentStyle}`;
                                var textForButton = "\xa0";
                                if(precentShow === 100 && didShowTextOnCol === false && row.getHours() < 22 ){ // don't show text on hrs past 10 p.m
                                    didShowTextOnCol = true;
                                    textForButton = event.name;
                                    buttonClassName += ` z-10`;
                                }
                                return (<button onClick={()=>onClick(event)} className={buttonClassName} key={event.id}>{textForButton}</button>);
                            })}
                    </div>
                })} 
            </div>
        })}
    </div> 

}

export default TimeSlots;