import React, { useState, useEffect } from 'react';
import { Table } from 'components/Table/Table';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { UsersIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@apollo/client';
import { GetMroCustomersDocument } from 'graphql/generated';
import { useSession } from 'contexts';
import { Link, useNavigate } from 'react-router-dom';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';

const Customers = function (props) {
  const [activeTab, setActiveTab] = useSaveState('customerActiveTab', 'All');
  const [query, setQuery] = useState<string>();
  const { user } = useSession();
  const { data: { mroCustomers } = {}, refetch } = useQuery(GetMroCustomersDocument, {
    variables: { where: { mroOrganizationId: { equals: user.mroOrganizationId } } },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (query !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        refetch(
          query
            ? {
                where: { mroOrganizationId: { equals: user.mroOrganizationId }, name: { search: query.trim().replaceAll(' ', ' & ') } },
              }
            : { where: { mroOrganizationId: { equals: user.mroOrganizationId } } }
        ).then(() => setActiveTab('All'));
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [query, refetch, user]);

  // TABLE COLUMNS
  const columns = React.useMemo(
    () => [
      {
        Header: 'Customer Info',
        accessor: (cust: any) => ({
          name: cust.name,
          email: cust.email,
          contacts: cust.mroContacts,
        }),
        className: 'grow w-1/2',
        Cell: ({ value }: any) => {
          return (
            <div className="flex items-center w-full">
              <div className="flex items-center justify-center font-bold text-base text-brand-electric bg-brand-pale border-brand-electric w-9 h-9 rounded mr-2">
                {value.name[0]}
              </div>
              <div className="flex items-center justify-between w-full max-w-screen-md">
                <div className="flex flex-col">
                  <span className="text-lg font-bold text-brand-dark">{value.name}</span>
                  <span className="-mt-1 text-brand-electric">{value.email}</span>
                </div>
                <div className="xl:flex hidden mr-12">
                  {value.contacts.map((contact, ndx) => {
                    let char = contact.firstName[0],
                      title = contact.firstName + ' ' + contact.lastName;
                    if (value.contacts.length > 3) {
                      if (ndx === value.contacts.length - 1) {
                        char = '+' + (value.contacts.length - 2);
                        title = `...and ${value.contacts.length - 2} more`;
                      } else if (ndx > 1) {
                        return null;
                      }
                    }
                    return (
                      <div
                        key={contact.id}
                        title={title}
                        className="h-8 w-8 bg-brand-pale text-brand-electric rounded-lg flex items-center justify-center font-bold border-white border-2 first:ml-0 -ml-2 uppercase">
                        {char}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Craft(s)',
        accessor: (cust) => cust.mroCrafts,
        className: 'grow w-1/6',
        Cell: ({ value, row }: any) => {
          if (!Array.isArray(value)) {
            value = [value];
          }
          return (
            <div className="flex flex-wrap -ml-[0.125rem] -my-[0.125rem]">
              {value.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      item.in ? 'bg-brand-pale text-brand-electric' : 'border-slate-300 bg-slate-50 text-slate-400'
                    } m-[0.125rem] flex items-center rounded px-1.5 py-0.5 border`}>
                    <span className="font-bold">{item.tailNumber}</span>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: 'Discrepancies',
        accessor: (cust) => 
          cust.mroWorkOrders.filter((order) => order.status !== "COMPLETED" && order.status !== "CLOSED")
          .reduce((a, b) => a + b.mroWorkOrderItems.filter((item) => item.status !== "COMPLETED" && item.status !== "ABORT")?.length, 0),
        className: 'grow w-1/6',
        Cell: ({ value, row }: any) => {
          return (
            <div
              className={`${
                value > 0 ? 'text-brand-electric border-brand-electric' : 'bg-slate-50 text-slate-400 border-slate-300'
              } flex justify-center items-center w-full rounded-full px-3 py-1 border`}>
              <span className="font-bold">{value > 0 ? `${value} Open` : 'None'}</span>
            </div>
          );
        },
      },
      {
        Header: 'Open Invoices',
        accessor: (cust) =>
          cust.mroWorkOrders.reduce((a, b) => {
            const x = b.mroInvoices?.filter((item) => item?.status === 'PENDING');
            return a + x?.length;
          }, 0),
        className: 'grow w-1/6',
        Cell: ({ value, row }: any) => {
          return (
            <div
              className={`${
                value > 0 ? 'bg-rose-50 text-rose-600 border-rose-500' : 'border-slate-300 bg-slate-100 text-slate-400'
              } border w-full flex justify-center items-center rounded px-2 py-1`}>
              <span className="font-bold">{value > 0 ? `${value} Outstanding` : 'None'}</span>
            </div>
          );
        },
      },
    ],
    []
  );


  // HANDLES FILTER TAB FUNCTIONALITY
  const tabs = ['All', 'Active', 'Inactive'];
  const tableData = React.useMemo(() => {
    switch (activeTab) {
      case 'All':
        return mroCustomers;
      case 'Active':
        return mroCustomers;
      case 'Inactive':
        return mroCustomers;
    }
  }, [mroCustomers, activeTab]);

  return (
    <>
      <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center">
          <UsersIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Customers</h1>
        </div>
        <div className="flex items-stretch gap-2 flex-wrap justify-end">
          <div className="relative flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
            <input
              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <Link
            to="/mro/customers/new"
            className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
            <PlusIcon className="h-3 w-3 mr-1" />
            Add New
          </Link>
        </div>
      </div>
      <div className="p-6 bg-white border rounded border-slate-300 w-full max-w-[calc(78rem-2px)] shadow-blue">
        <div className="flex justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2 mb-3">
          <Tabs items={tabs} activeItem={activeTab} setActiveItem={setActiveTab} />
        </div>
        <Table
          columns={columns}
          data={tableData ?? []}
          onRowClick={(row) => {
            navigate('/mro/customers/' + row.id);
          }}
        />
      </div>
    </>
  );
};

export default Customers;
